import {
  adminUpdatePharmacistCapacity,
  IAdminPharmacistCapacityList,
} from '@pharmaplan/common';
import {
  Constants,
  DynamicTableCell,
  TextFieldVariants,
} from '../../../helpers/Constants';
import editIcon from '../../../assets/svg/updateNoteIcon.svg';
import deleteIcon from '../../../assets/svg/delete.svg';
import strings from '../../../localization';
import { IInputModalConfig } from '../../Modals/InputModal/InputModal';
import genericClasses from '../../../theme/GenericClasses';
import styles from './styles';
import { IHandlePublishSignature } from '../../../hooks/Admin/usePublishConfigurations';

export const pharmacistCapacityHeaders = () =>
  [
    {
      key: '0',
      label: strings.from,
      isCenterAligned: true,
    },
    {
      key: '1',
      label: strings.to,
      isCenterAligned: true,
    },
    {
      key: '2',
      label: strings.published,
    },
    {
      key: '3',
      label: strings.action,
      isCenterAligned: true,
    },
  ];

export const pharmacistCapacityMap = (
  capacityList: IAdminPharmacistCapacityList[],
  publishedRecords: Record<string, boolean>,
  handlePublish: IHandlePublishSignature,
  updateAction: (capacityId: string) => void,
  showDeleteConfirmation: (capacityId: string) => void,
  canChangeParameters: boolean,
  canDelete: boolean,
) =>
  capacityList?.map((item) => {
    const { pharmacistCapacityId, from, to, inUse } = item ?? {};

    const value = [
      canChangeParameters && {
        key: '1',
        icon: editIcon,
        show: false,
        tooltip: strings.edit,
        onClick: () =>
          updateAction?.(pharmacistCapacityId),
      },
      canDelete && {
        key: '2',
        icon: deleteIcon,
        show: false,
        tooltip: strings.delete,
        onClick: () =>
          showDeleteConfirmation(pharmacistCapacityId),
      },
    ];

    return {
      key: pharmacistCapacityId,
      data: [
        {
          key: `0-${pharmacistCapacityId}`,
          value: from,
          type: DynamicTableCell.text,
          isCenterAligned: true,
        },
        {
          key: `1-${pharmacistCapacityId}`,
          value: to,
          type: DynamicTableCell.text,
          isCenterAligned: true,
        },
        {
          key: `2-${pharmacistCapacityId}`,
          value: {
            checked: !!publishedRecords[pharmacistCapacityId],
            tooltipText: strings.inUse,
            disabled: inUse || !canChangeParameters,
            handlePress: handlePublish?.(
              {
                pharmacistCapacityId,
                from,
                to,
                published: !publishedRecords[pharmacistCapacityId],
              },
              'pharmacistCapacityId',
              adminUpdatePharmacistCapacity,
            ),
          },
          type: DynamicTableCell.checkbox,
          isCenterAligned: true,
        },
        !inUse
          ? {
            key: `3-${pharmacistCapacityId}`,
            isCenterAligned: true,
            value,
            type: DynamicTableCell.bar,
          }
          : {
            key: `4-${pharmacistCapacityId}`,
            value: {
              label: strings.inUse,
              style: styles.chipStyle,
            },
            type: DynamicTableCell.chip,
            isCenterAligned: true,
          },
      ],
    };
  });

export const addNewPharmacistCapacityForm: IInputModalConfig[] = [
  {
    key: '0',
    name: 'from',
    type: Constants.formInputType.text,
    gridLabel: strings.from,
    customTextFieldStyle: genericClasses.customFilledInput,
    variant: TextFieldVariants.filled,
    fieldXs: 8,
    labelXs: 4,
  },
  {
    key: '1',
    name: 'to',
    type: Constants.formInputType.text,
    gridLabel: strings.to,
    customTextFieldStyle: genericClasses.customFilledInput,
    variant: TextFieldVariants.filled,
    fieldXs: 8,
    labelXs: 4,
  },
  {
    key: '4',
    name: 'published',
    type: Constants.formInputType.checkbox,
    customClass: genericClasses.checkboxContainer,
    customCheckboxStyle: genericClasses.checkboxStyle,
    gridLabelClass: styles.gridLabel,
    gridLabel: strings.publish,
    label: ' ',
    fieldXs: 8,
    labelXs: 4,
  },
];

export const canCreateNew = (
  canChangeParameters: boolean,
  object: { Component: React.ReactNode; key: string; onClick?: () => void },
) => {
  if (canChangeParameters) {
    return [object];
  }
  return [];
};
