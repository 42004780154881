import { DateTime } from 'luxon';

import {
  IIncomeDetailsData,
  IIncomeReportsData,
  TimeFormat,
  convertToDecimals,
} from '@pharmaplan/common';

import strings from '../../../localization';
import {
  DynamicTableCell,
  MomentTimeFormats,
} from '../../../helpers/Constants';
import { getTimeWithDiff } from '../../Modals/WorkshiftDetails/utils';
import showPassword from '../../../assets/svg/showPassword.svg';

import { getDayMonthDateYear } from '../helpers';

export const summaryConfig = (
  totalRevenue: number,
  totalWorkshifts: number,
) =>
  [
    {
      key: 0,
      label: strings.totalRevenue,
      amount: `$${convertToDecimals(totalRevenue)}`,
    },
    {
      key: 1,
      label: strings.totalWorkshifts,
      amount: `${totalWorkshifts}`,
    },
  ];

export const calculateTotalRevenueAndWorkshifts = (
  data: IIncomeReportsData[],
) => {
  let totalRevenue = 0;
  let totalWorkshiftsCount = 0;

  data.forEach((item) => {
    totalRevenue += item.revenue;
    totalWorkshiftsCount += item.totalCount;
  });

  return { totalRevenue, totalWorkshiftsCount };
};

export const incomeReportHeaders = () =>
  [
    { key: '0', label: strings.banner },
    { key: '1', label: strings.pharmacy },
    { key: '2', label: strings.city },
    { key: '3', label: strings.totalRevenue, isCenterAligned: true },
    { key: '4', label: strings.totalWorkshifts, isCenterAligned: true },
    { key: '5', label: strings.action },
  ];

export const incomeDetailsHeaders = () =>
  [
    { key: '0', label: strings.workshiftDateText },
    { key: '1', label: strings.hourlyRate },
    { key: '2', label: strings.hours },
    { key: '3', label: strings.contractValue, isCenterAligned: true },
    { key: '4', label: strings.accomodation, isCenterAligned: true },
    { key: '5', label: strings.meal, isCenterAligned: true },
    { key: '6', label: strings.travel, isCenterAligned: true },
    { key: '7', label: strings.emergencyServiceFees, isCenterAligned: true },
    { key: '8', label: strings.total, isCenterAligned: true },
  ];

export const formatDate = (date: string) => {
  if (date) {
    return DateTime.fromISO(date, { setZone: true }).toFormat(
      MomentTimeFormats.fullDate,
    );
  }
  return '';
};

export const incomeReportMapper = (
  data: Array<IIncomeReportsData>,
  getLogo: (logoId: string) => string | undefined,
  setPharmacyId: React.Dispatch<React.SetStateAction<string>>,
) =>
  data?.map((item) => {
    const { logoId, pharmacyName, city, totalCount, revenue, pharmacyId } = item ?? {};

    return {
      key: pharmacyId,
      data: [
        {
          key: `0-${pharmacyId}`,
          value: getLogo(logoId),
          type: DynamicTableCell.icon,
        },
        {
          key: `1-${pharmacyId}`,
          value: pharmacyName,
          type: DynamicTableCell.text,
        },
        {
          key: `2-${pharmacyId}`,
          value: city,
          type: DynamicTableCell.text,
        },
        {
          key: `3-${pharmacyId}`,
          value: `$${revenue}`,
          isCenterAligned: true,
          type: DynamicTableCell.text,
        },
        {
          key: `4-${pharmacyId}`,
          value: totalCount,
          isCenterAligned: true,
          type: DynamicTableCell.text,
        },
        {
          key: `5-${pharmacyId}`,
          show: true,
          value: [{
            key: '1',
            icon: showPassword,
            tooltip: strings.showDetails,
            onClick: () => {
              setPharmacyId(pharmacyId);
            },
          }],
          type: DynamicTableCell.bar,
        },
      ],
    };
  });

export const hasValue = (value: number | null | undefined) => {
  if (value === null || value === undefined) {
    return '$0';
  }
  return `$${convertToDecimals(value)}`;
};

export const incomeDetailsMapper = (
  data: Array<IIncomeDetailsData>,
  timeFormat: TimeFormat,
) =>
  data?.map((item) => {
    const {
      selectedDate,
      paidHourlyRate,
      bookingCost,
      accommodationAllowance,
      mealAllowance,
      travelAllowance,
      pharmacyId,
      startDate,
      emergencyFees,
      endDate,
    } = item ?? {};

    const total = bookingCost + accommodationAllowance + mealAllowance + travelAllowance + emergencyFees;

    return {
      key: selectedDate,
      data: [
        {
          key: `${pharmacyId}-0`,
          value: getDayMonthDateYear(selectedDate),
          type: DynamicTableCell.text,
        },
        {
          key: `${pharmacyId}-1`,
          value: `$${paidHourlyRate}`,
          type: DynamicTableCell.text,
        },
        {
          key: `${pharmacyId}-2`,
          value: `${getTimeWithDiff(startDate, endDate, timeFormat)}`,
          type: DynamicTableCell.text,
        },
        {
          key: `${pharmacyId}-3`,
          value: hasValue(bookingCost),
          isCenterAligned: true,
          type: DynamicTableCell.text,
        },
        {
          key: `${pharmacyId}-4`,
          value: hasValue(accommodationAllowance),
          isCenterAligned: true,
          type: DynamicTableCell.text,
        },
        {
          key: `${pharmacyId}-5`,
          value: hasValue(travelAllowance),
          isCenterAligned: true,
          type: DynamicTableCell.text,
        },
        {
          key: `${pharmacyId}-6`,
          value: hasValue(mealAllowance),
          isCenterAligned: true,
          type: DynamicTableCell.text,
        },
        {
          key: `${pharmacyId}-7`,
          value: hasValue(emergencyFees),
          isCenterAligned: true,
          type: DynamicTableCell.text,
        },
        {
          key: `${pharmacyId}-8`,
          value: hasValue(total),
          isCenterAligned: true,
          type: DynamicTableCell.text,
        },
      ],
    };
  });
