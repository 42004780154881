import React, { useEffect, useState } from 'react';

import { FormikValues, useFormik } from 'formik';

import {
  adminActions,
  getPharmaciesForPharmacyAdmin,
  getPublishedSoftwareList,
  pharmaciesForPharmacyAdmin,
  publishedSoftwareList,
  successSelector,
} from '@pharmaplan/common';

import useUserProfileLink from '../../../../hooks/Admin/useUserProfileLink';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { IDynamicTableObject } from '../../../DynamicTable/types';
import { setDialog } from '../../../../reducers/dialogReducer';
import useGetItems from '../../../../hooks/useGetItems';
import DynamicTable from '../../../DynamicTable';
import strings from '../../../../localization';

import AppointExistingPharmacyHeader from './AppointExistingPharmacyHeader';
import { appointPharmaciesHeaders, appointPharmaciesRows } from './helper';
import AppointModal from './AppointModal';

export interface IAppointAction {
  id: string;
  email: string;
  name: string;
}

const AppointExistingPharmacy = () => {
  const dispatch = useAppDispatch();

  const loadSuccess = useAppSelector((state) =>
    successSelector([adminActions.getPharmaciesForPharmacyAdmin], state));

  const [page, setPage] = useState(1);

  const pharmacies = useAppSelector(pharmaciesForPharmacyAdmin);
  const softwares = useAppSelector(publishedSoftwareList);

  const { data, totalCount } = pharmacies ?? {};

  const { getImage, getSoftwareName } = useGetItems();
  const { goToUserProfile } = useUserProfileLink();

  const goToAppointPharmacy = ({ name, email, id }:IAppointAction) =>
    () => {
      dispatch(
        setDialog({
          heading: {
            title: '',
          },
          showCloseButton: true,
          Component: (
            <AppointModal email={email} pharmacyId={id} pharmacyName={name} />
          ),
        }),
      );
    };

  useEffect(() => {
    dispatch(getPharmaciesForPharmacyAdmin({ page }));
    dispatch(getPublishedSoftwareList());
  }, []);

  const table: IDynamicTableObject = {
    title: strings.appointExistingPharmacy,
    headerBar: [],
    headers: appointPharmaciesHeaders(),
    rows: appointPharmaciesRows({
      appointAction: goToAppointPharmacy,
      goToUserProfile,
      getImage,
      getSoftware: (id: string) =>
        getSoftwareName(softwares, id),
      data,
    }),
  };

  const handleSubmit = (values: FormikValues) => {
    const { banners, userText, contactName, city, softwareId } = values ?? {};
    setPage(1);
    dispatch(
      getPharmaciesForPharmacyAdmin({
        page: 1,
        banners,
        contactName,
        userText,
        city,
        softwareId,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      banners: '',
      userText: '',
      contactName: '',
      city: '',
      softwareId: '',
    },
    onSubmit: handleSubmit,
  });

  const handleReset = () => {
    formik.resetForm();
    setPage(1);
    dispatch(getPharmaciesForPharmacyAdmin({ page: 1 }));
  };

  const handlePagination = (_: unknown, selectedPage: number) => {
    const gotoPage = selectedPage + 1;
    setPage(gotoPage);
    dispatch(
      getPharmaciesForPharmacyAdmin({ page: gotoPage, ...formik.values }),
    );
  };

  return (
    <DynamicTable
      table={table}
      loadSuccess={loadSuccess}
      totalCount={totalCount}
      page={page}
      handlePagination={handlePagination}
      customHeader={(
        <AppointExistingPharmacyHeader
          formik={formik}
          handleReset={handleReset}
        />
      )}
    />
  );
};

export default AppointExistingPharmacy;
