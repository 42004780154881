import {
  IAdminPharmacyListBaseData,
  TypeOfUser,
  convertToDecimals,
} from '@pharmaplan/common';
import {
  Constants,
  DynamicTableCell,
  OtherScreens,
} from '../../../helpers/Constants';

import strings from '../../../localization';
import {
  AppointedKeysToRemove,
  IPharmacyListActionsParams,
} from '../../../hooks/Admin/PharmacyList/usePharmacyListAction';
import { IMenuActions } from '../../common/CustomAvatarSection/CustomAvatarSection';
import { getDayMonthDateYear } from '../../Reports/helpers';
import { IUserProfileLinkSignature } from '../../../hooks/Admin/useUserProfileLink';

const { text, customLink, customMenu, button, icon } = DynamicTableCell;
const { text: textVariant } = Constants.variant;
const { Deactivate, ViewPharmacyAdmins } = AppointedKeysToRemove;
const keysToRemove = [Deactivate, ViewPharmacyAdmins];

export const activePharmaciesHeader = () =>
  [
    {
      key: '0',
      label: strings.banner,
    },
    {
      key: '1',
      label: strings.bannerNumber,
    },
    {
      key: '2',
      label: strings.pharmacyName,
    },
    {
      key: '3',
      label: strings.contactName,
    },
    {
      key: '4',
      label: strings.email,
    },
    {
      key: '5',
      label: strings.mobile,
    },
    {
      key: '6',
      label: strings.city,
    },
    {
      key: '7',
      label: strings.pharmacyHourlyRate,
    },
    {
      key: '8',
      label: strings.action,
      isCenterAligned: true,
    },
  ];

export const deactivatedPharmaciesHeader = () =>
  [
    ...activePharmaciesHeader().slice(0, 6),
    { key: '6', label: strings.deactivatedDate },
    ...activePharmaciesHeader().slice(6),
  ];

export const deletedPharmaciesHeader = () =>
  [
    {
      key: '0',
      label: strings.banner,
    },
    {
      key: '1',
      label: strings.bannerNumber,
    },
    {
      key: '2',
      label: strings.pharmacyName,
    },
    {
      key: '3',
      label: strings.contactName,
    },
    {
      key: '4',
      label: strings.deletedDate,
    },
    {
      key: '5',
      label: strings.pharmacyHourlyRate,
      isCenterAligned: true,
    },
  ];

export const activePharmaciesList = (
  data: IAdminPharmacyListBaseData[],
  getImage: (logoId: string) => string,
  goToUserProfile: IUserProfileLinkSignature,
  actions?: (params: IPharmacyListActionsParams) => Array<IMenuActions>,
) =>
  data.map((item) => {
    const {
      logoId,
      name,
      bannerNumber,
      pharmacyId,
      contactName,
      email,
      mobile,
      pharmacyHourlyRate,
      city,
    } = item ?? {};

    return {
      key: pharmacyId,
      data: [
        {
          key: `0-${pharmacyId}`,
          value: getImage(logoId),
          type: icon,
        },
        {
          key: `1-${pharmacyId}`,
          value: bannerNumber,
          type: text,
        },
        {
          key: `2-${pharmacyId}`,
          maxWidth: 200,
          value: {
            label: name,
            handlePress: goToUserProfile({
              personnelId: pharmacyId,
              userType: TypeOfUser.pharmacy,
            }),
          },
          type: customLink,
        },
        {
          key: `3-${pharmacyId}`,
          value: contactName,
          maxWidth: 200,
          type: text,
        },
        {
          key: `4-${pharmacyId}`,
          value: email,
          type: text,
          maxWidth: 200,
        },
        {
          key: `5-${pharmacyId}`,
          value: mobile,
          type: text,
        },
        {
          key: `6-${pharmacyId}`,
          value: city,
          type: text,
        },
        {
          key: `7-${pharmacyId}`,
          value: `$${convertToDecimals(pharmacyHourlyRate)}`,
          isCenterAligned: true,
          type: text,
        },
        {
          key: `8-${pharmacyId}`,
          value: {
            customMenuActions: actions?.({ pharmacyId, name, email }),
          },
          type: customMenu,
        },
      ],
    };
  });

export const deactivatedPharmaciesList = (
  data: IAdminPharmacyListBaseData[],
  getImage: (logoId: string) => string,
  goToUserProfile: IUserProfileLinkSignature,
  activateModal: (pharmacyId: string) => () => void,
) =>
  data.map((item) => {
    const {
      logoId,
      name,
      bannerNumber,
      pharmacyId,
      contactName,
      email,
      mobile,
      lastActivityDate,
      pharmacyHourlyRate,
      city,
    } = item ?? {};

    const deactivatedDate = getDayMonthDateYear(lastActivityDate ?? '');

    return {
      key: pharmacyId,
      data: [
        {
          key: `0-${pharmacyId}`,
          value: getImage(logoId),
          type: icon,
        },
        {
          key: `1-${pharmacyId}`,
          value: bannerNumber,
          type: text,
        },
        {
          key: `2-${pharmacyId}`,
          maxWidth: 200,
          value: {
            label: name,
            handlePress: goToUserProfile({
              personnelId: pharmacyId,
              userType: TypeOfUser.pharmacy,
            }),
          },
          type: customLink,
        },
        {
          key: `3-${pharmacyId}`,
          value: contactName,
          maxWidth: 200,
          type: text,
        },
        {
          key: `4-${pharmacyId}`,
          value: email,
          type: text,
          maxWidth: 200,
        },
        {
          key: `4-${pharmacyId}`,
          value: mobile,
          type: text,
        },
        {
          key: `5-${pharmacyId}`,
          value: deactivatedDate,
          type: text,
        },
        {
          key: `6-${pharmacyId}`,
          value: city,
          type: text,
        },
        {
          key: `7-${pharmacyId}`,
          value: `$${convertToDecimals(pharmacyHourlyRate)}`,
          isCenterAligned: true,
          type: text,
        },
        {
          key: `8-${pharmacyId}`,
          value: {
            label: strings.activate,
            handlePress: activateModal(pharmacyId),
            variant: textVariant,
          },
          show: true,
          type: button,
        },
      ],
    };
  });

export const deletedPharmaciesList = (
  data: IAdminPharmacyListBaseData[],
  getImage: (logoId: string) => string,
  goToUserProfile: IUserProfileLinkSignature,
) =>
  data.map((item) => {
    const {
      logoId,
      name,
      bannerNumber,
      pharmacyId,
      contactName,
      deletedDate,
      userId,
      pharmacyHourlyRate,
    } = item ?? {};

    const date = getDayMonthDateYear(deletedDate ?? '');

    return {
      key: pharmacyId,
      data: [
        {
          key: `0-${pharmacyId}`,
          value: getImage(logoId),
          type: icon,
        },
        {
          key: `1-${pharmacyId}`,
          value: bannerNumber,
          type: text,
        },
        {
          key: `2-${pharmacyId}`,
          maxWidth: 200,
          value: {
            label: name,
            handlePress: goToUserProfile({
              personnelId: userId,
              userType: TypeOfUser.pharmacy,
              fromScreen: OtherScreens.DeletedPharmacyList,
            }),
          },
          type: customLink,
        },
        {
          key: `3-${pharmacyId}`,
          value: contactName,
          maxWidth: 200,
          type: text,
        },
        {
          key: `4-${pharmacyId}`,
          value: date,
          type: text,
        },
        {
          key: `5-${pharmacyId}`,
          value: `$${convertToDecimals(pharmacyHourlyRate)}`,
          isCenterAligned: true,
          type: text,
        },
      ],
    };
  });

export const appointedPharmaciesList = (
  data: IAdminPharmacyListBaseData[],
  getImage: (logoId: string) => string,
  goToUserProfile: IUserProfileLinkSignature,
  actions?: (params: IPharmacyListActionsParams) => Array<IMenuActions>,
) =>
  data.map((item) => {
    const {
      logoId,
      name,
      bannerNumber,
      pharmacyId,
      contactName,
      email,
      mobile,
      pharmacyHourlyRate,
      city,
    } = item ?? {};

    return {
      key: pharmacyId,
      data: [
        {
          key: `0-${pharmacyId}`,
          value: getImage(logoId),
          type: icon,
        },
        {
          key: `1-${pharmacyId}`,
          value: bannerNumber,
          type: text,
        },
        {
          key: `2-${pharmacyId}`,
          maxWidth: 200,
          value: {
            label: name,
            handlePress: goToUserProfile({
              personnelId: pharmacyId,
              fromScreen: OtherScreens.AppointedPharmacies,
              userType: TypeOfUser.pharmacy,
            }),
          },
          type: customLink,
        },
        {
          key: `3-${pharmacyId}`,
          value: contactName,
          maxWidth: 200,
          type: text,
        },
        {
          key: `4-${pharmacyId}`,
          value: email,
          type: text,
          maxWidth: 200,
        },
        {
          key: `5-${pharmacyId}`,
          value: mobile,
          type: text,
        },
        {
          key: `6-${pharmacyId}`,
          value: city,
          type: text,
        },
        {
          key: `7-${pharmacyId}`,
          value: `$${convertToDecimals(pharmacyHourlyRate)}`,
          isCenterAligned: true,
          type: text,
        },
        {
          key: `8-${pharmacyId}`,
          value: {
            customMenuActions: actions?.({ pharmacyId, name, email }).filter(
              (inItem) =>
                !keysToRemove.includes(inItem.key),
            ),
          },
          type: customMenu,
        },
      ],
    };
  });
