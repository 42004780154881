import React from 'react';

import strings from '../../../localization';
import useLogs from '../../../hooks/Admin/Logs/useLogs';

import DynamicTable from '../../DynamicTable';
import EmptyIncompatibleMatches from '../IncompatibleMatches/EmptyIncompatibleMatches';

import ActivityLogsHeader from './LogsHeader/LogsHeader';

const Logs = () => {
  const {
    table,
    page,
    totalCount,
    handlePagination,
    handleReset,
    formik,
    data,
    loadSuccess,
  } = useLogs();

  const noData = data.length === 0;

  let emptyContainerComponent = null;

  if (loadSuccess) {
    if (noData) {
      emptyContainerComponent = <EmptyIncompatibleMatches message={strings.noDataAvailable} />;
    }
  }

  return (
    <DynamicTable
      table={table}
      loadSuccess={loadSuccess}
      totalCount={totalCount}
      page={page}
      handlePagination={handlePagination}
      showHeader={false}
      customHeader={
        <ActivityLogsHeader formik={formik} handleReset={handleReset} />
      }
      emptyContainerComponent={emptyContainerComponent}
    />
  );
};

export default Logs;
