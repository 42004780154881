import React from 'react';

import { Box, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Navbar from '../Navbar';

import classes from './style';
import NavToolBar from '../Navbar/NavToolBar';
import useHandleBookingCancel from '../../hooks/useHandleBookingCancel';

const { mainContainer, content, contentContainer } = classes;

const Home = () => {
  useHandleBookingCancel();
  return (
    <Box sx={mainContainer} component="div">
      <Grid sx={contentContainer} item sm={11}>
        {/* TopBar */}
        <Box component="div">
          <Navbar BottomToolbar={<NavToolBar />} />
        </Box>
        {/* Content */}
        <Box sx={content}>
          <Outlet />
        </Box>
      </Grid>
    </Box>
  );
};

export default Home;
