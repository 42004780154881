import { Box } from '@mui/system';
import {
  calendarReducerInitialState,
  pharmacistFilterValues,
  updateFilter,
} from '@pharmaplan/common';
import { useFormik } from 'formik';
import React, { useEffect, useImperativeHandle } from 'react';
import { dashboardFilter } from '../../../../helpers/Filters';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import FilterGenerator from '../../../common/FilterGenerator';
import classes from './styles';

interface IDashboardFilter {
  setFilterState: (
    value: React.SetStateAction<{
      submitted: boolean;
      showFilter: boolean;
    }>
  ) => void;
  filterState: {
    submitted: boolean;
    showFilter: boolean;
  };
}

const DashboardFilter = React.forwardRef(({ filterState, setFilterState }: IDashboardFilter, ref) => {
  const filterValues = useAppSelector(pharmacistFilterValues);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      ...calendarReducerInitialState?.filter,
      weekVolume: '{}',
      weekendVolume: '{}',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setFilterState({ ...filterState, submitted: true });
      dispatch(updateFilter({ ...values }));
    },
  });

  const resetFilter = () => {
    dispatch(
      updateFilter({
        ...calendarReducerInitialState?.filter,
        weekVolume: '{}',
        weekendVolume: '{}',
      }),
    );
    setFilterState({ ...filterState, submitted: false });
    formik.resetForm();
  };

  useEffect(() =>
    () => {
      dispatch(
        updateFilter({
          ...calendarReducerInitialState?.filter,
          weekVolume: null,
          weekendVolume: null,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useImperativeHandle((ref), () =>
    ({
      reset() {
        resetFilter();
      },
      clear() {
        formik.resetForm();
      },
    }));

  return (
    <Box component="div" sx={classes.container}>
      {filterState.showFilter && (
        <FilterGenerator
          isDashboard
          filter={dashboardFilter(filterValues, resetFilter)}
          formik={formik}
        />
      )}
    </Box>
  );
});

export default DashboardFilter;
