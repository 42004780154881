import React, { FC } from 'react';

import { Box, Button, Typography } from '@mui/material';

import classes from './style';

interface IPendingTabConfig {
  key: string;
  tabName: string;
  onClick: (tab: any) => void;
}

interface IPendingTabs {
  header?: string;
  tabConfig: Array<IPendingTabConfig>;
  selectedTab: string;
  EndAdornment?: React.ReactNode | null;
}

const PendingTabs: FC<IPendingTabs> = ({
  header,
  tabConfig,
  selectedTab,
  EndAdornment,
}) =>
  (
    <>
      <Box sx={classes.headerContainer}>
        <Typography sx={classes.headerFont}>{header}</Typography>
        {EndAdornment}
      </Box>
      <Box sx={classes.root}>
        {tabConfig.map((item) => {
          const { key, tabName, onClick } = item ?? {};
          const isSelected = key === selectedTab;

          return (
            <Button
              key={key}
              onClick={() =>
                onClick(key)}
              sx={[
                classes.navButton,
                isSelected ? classes.activeButton : classes.inActive,
              ]}
              variant="text"
            >
              {tabName}
            </Button>
          );
        })}
      </Box>
    </>
  );

export default PendingTabs;
