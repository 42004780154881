import {
  adminUpdatePharmacyChain,
  IAdminPharmacyChainResponse,
} from '@pharmaplan/common';
import { DynamicTableCell } from '../../../helpers/Constants';
import strings from '../../../localization';
import editIcon from '../../../assets/svg/updateNoteIcon.svg';
import deleteIcon from '../../../assets/svg/delete.svg';
import defaultBanner from '../../../assets/svg/defaultBanner.svg';
import { PublishCallbackType } from '../../../hooks/Admin/usePublishConfigurations';

export const pharmacyChainHeaders = () =>
  [
    {
      key: '0',
      label: strings.banner,
    },
    {
      key: '1',
      label: strings.name,
    },
    {
      key: '2',
      label: strings.published,
    },
    {
      key: '3',
      label: strings.action,
    },
  ];

export const pharmacyChainMap = (
  capacityList: IAdminPharmacyChainResponse[],
  canChangeParameters: boolean,
  canDelete: boolean,
  publishedRecords?: Record<string, boolean>,
  handlePublish?: <T, key extends keyof T>(
    params: T,
    id: key,
    callback: PublishCallbackType<T>
  ) => () => void,
  updateAction?: (chainId: string) => void,
  deleteAction?: (chainId: string) => void,
) =>
  capacityList?.map((item) => {
    const { pharmacyChainId, name, logoImage, inUse } = item ?? {};

    const value = [
      canChangeParameters && {
        key: '1',
        icon: editIcon,
        show: false,
        tooltip: strings.edit,
        onClick: () =>
          updateAction?.(pharmacyChainId),
      },
      canDelete
        && !inUse && {
        key: '2',
        icon: deleteIcon,
        show: false,
        tooltip: strings.delete,
        onClick: () =>
          deleteAction?.(pharmacyChainId),
      },
    ];

    return {
      key: pharmacyChainId,
      data: [
        {
          key: `0-${pharmacyChainId}`,
          value: logoImage ?? defaultBanner,
          type: DynamicTableCell.icon,
        },
        {
          key: `1-${pharmacyChainId}`,
          value: name,
          type: DynamicTableCell.text,
        },
        {
          key: `2-${pharmacyChainId}`,
          value: {
            checked: !!publishedRecords?.[pharmacyChainId],
            tooltipText: strings.inUse,
            disabled: inUse || !canChangeParameters,
            handlePress: handlePublish?.(
              {
                pharmacyChainId,
                name,
                form: null,
                published: !publishedRecords?.[pharmacyChainId],
                isLogoChanged: false,
              },
              'pharmacyChainId',
              adminUpdatePharmacyChain,
            ),
          },
          isCenterAligned: true,
          type: DynamicTableCell.checkbox,
        },
        {
          key: `3-${pharmacyChainId}`,
          value,
          type: DynamicTableCell.bar,
        },
      ],
    };
  });
