import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  deleteResume,
  getPharmacistResume,
  pharmacistActions,
  pharmacistResume,
  resetStatus,
  uploadResume,
} from '@pharmaplan/common';
import { Constants } from '../../../helpers/Constants';
import strings from '../../../localization';
import classes from './style';
import UploadBox from './UploadBox';
import UploadDetails from './UploadDetails';
import uploadedFileIcon from '../../../assets/svg/uploadedFileIcon.svg';
import uploadeImageIcon from '../../../assets/svg/uploadeImageIcon.svg';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { successSelector } from '../../../selectors/statusSelector';

interface ICustomUploadInput {
  label: string;
  uploadType: string;
}
const extractFile = (
  e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>,
) => {
  if ('dataTransfer' in e) {
    return e.dataTransfer.files;
  }
  return e.target.files;
};

const CustomUploadInput = ({ label, uploadType }: ICustomUploadInput) => {
  const [fileType, setFileType] = useState({
    accept: Constants.null,
    note: Constants.null,
    icon: Constants.null,
  });
  const [progress, setProgress] = useState(0);
  const uploadSuccess = useAppSelector((state) =>
    successSelector(
      [pharmacistActions.uploadResume],
      state,
    ));

  const [uploadedFile, setUploadedFile] = useState('');

  const dispatch = useAppDispatch();
  const resume = useAppSelector(pharmacistResume);

  const handleFileType = () => {
    switch (uploadType) {
      case Constants.upload.type.pdf:
        setFileType({
          accept: Constants.upload.ext.pdf,
          note: strings.pdfUploadNote,
          icon: uploadedFileIcon,
        });
        break;
      case Constants.upload.type.image:
        setFileType({
          accept: Constants.upload.ext.image,
          note: strings.imageUploadNote,
          icon: uploadeImageIcon,
        });
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    handleFileType();
    dispatch(getPharmacistResume());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      dispatch(getPharmacistResume());
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSuccess]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 300);
    return () => {
      clearInterval(timer);
    };
  }, [uploadedFile]);

  useEffect(() => {
    if (resume.fileName) {
      setUploadedFile(resume.fileName);
      setProgress(100);
    }
  }, [resume.fileName]);

  const handleResumeUpload = (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>,
  ) => {
    const file = extractFile(e);
    if (file && file?.length > 0) {
      const fileToLoad = file[0];
      const fileName = fileToLoad.name;
      const fileReader = new FileReader();
      fileReader.onload = (fileLoadedEvent) => {
        setUploadedFile(fileName);
        dispatch(
          uploadResume({
            path: fileName,
            document: new Uint8Array(fileLoadedEvent?.target?.result as Buffer) as Buffer,
          }),
        );
      };
      fileReader.readAsArrayBuffer(fileToLoad);
    }
  };

  const handleDelete = () => {
    dispatch(deleteResume());
    setProgress(0);
    setUploadedFile('');
  };

  return (
    <Grid sx={classes.uploadContainer} flexDirection="column" container>
      <Box sx={classes.customUploadInputLabel} component="div">
        {label}
      </Box>
      {uploadedFile ? (
        <UploadDetails
          handleDelete={handleDelete}
          progress={progress}
          fileName={uploadedFile}
          fileType={fileType}
        />
      ) : (
        <UploadBox
          fileType={fileType}
          onChange={handleResumeUpload}
          onDrop={handleResumeUpload}
        />
      )}
    </Grid>
  );
};

export default CustomUploadInput;
