import { TimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation';
import {
  errorType,
  getErrorType,
  getTypeOfUser,
  TypeOfUser,
  userConfigurations,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { FormikProps } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';
import { setDisableAvailability } from '../../../reducers/mainCalendarReducer';
import genericClasses from '../../../theme/GenericClasses';
import CustomTimePicker from '../CustomTimePicker';
import classes from './styles';
import { TextFieldVariants, timeLimits } from '../../../helpers/Constants';
import FormikError from '../FormikError';
import useAdmin from '../../../hooks/useAdmin';

interface IDateTimeRange {
  formik: FormikProps<any>;
  startName: string;
  startNameLabel?: string;
  endName: string;
  endNameLabel?: string;
  variant?: TextFieldVariants;
  horizontal?: boolean;
  removeMargin?: boolean;
  startPlaceholder?: string;
  endPlaceholder?: string;
  disabled?: boolean;
  allowValidOnly?: boolean;
  removeLabel?: boolean;
  customStartHandler?: () => void;
  usePreFilledColors?: boolean;
}
const defaultProps = {
  horizontal: true,
  startNameLabel: '',
  endNameLabel: '',
  removeMargin: false,
  removeLabel: false,
};

const DateTimeRange = ({
  formik,
  startName,
  startNameLabel,
  endNameLabel,
  disabled,
  allowValidOnly = false,
  endName,
  variant,
  horizontal,
  removeMargin,
  startPlaceholder,
  endPlaceholder,
  removeLabel,
  customStartHandler,
  usePreFilledColors,
}: IDateTimeRange) => {
  const dispatch = useAppDispatch();

  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const { startMax, startMin, endMax } = timeLimits[timeFormat];

  const isToday = Math.round(
    DateTime.fromISO(formik.values.selectedDate, { zone: 'utc' }).diffNow(
      'day',
    ).days,
  ) === -1;

  const userType = useAppSelector(getTypeOfUser);
  const isPharmacy = userType === TypeOfUser.pharmacy;
  const { isAdmin } = useAdmin();

  const validateDate = (isAdmin || isPharmacy) && !allowValidOnly;

  const configurations = useAppSelector(userConfigurations);
  const { minHourWorkshift } = configurations ?? {};

  const typeOfError = useAppSelector(getErrorType);
  const horizontalErrorStyles = horizontal
    ? {
      container: classes.horizontalErrorContainer,
      label: classes.horizontalLabel,
    }
    : {};

  useEffect(() => {
    if (typeOfError === errorType.refreshConfigurations) {
      formik.setFieldValue(startName, null);
      formik.setFieldValue(endName, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOfError]);

  const errorConfig = {
    [startName]: {
      minTime: isToday
        ? strings.currentTimeValidation
        : strings.formatString(
          strings.timeAboveValidation,
          strings.startTime,
          startMin,
        ),
      maxTime: strings.formatString(
        strings.timeBelowValidation,
        strings.startTime,
        startMax,
      ),
      invalidDate: strings.pleaseEnterValidTime,
    },
    [endName]: {
      minTime: strings.formatString(
        strings.ensureTimeIsAhead,
        minHourWorkshift ?? 4,
      ) as string,
      maxTime: strings.formatString(
        strings.timeBelowValidation,
        strings.endTime,
        endMax,
      ),
      invalidDate: strings.pleaseEnterValidTime,
    },
  };
  const [validDate, setValidDate] = useState<{
    minStart: Date | null;
    minEnd: Date | null;
  }>({
    minStart: null,
    minEnd: null,
  });

  const [error, setError] = useState<{
    startError: string | string[] | null;
    endError: string | string[] | null;
  }>({
    startError: null,
    endError: null,
  });

  const handleStartMinimum = () => {
    if (isToday) {
      setValidDate((prev) =>
        ({
          ...prev,
          minStart: DateTime.now().toJSDate(),
        }));
      return;
    }
    setValidDate((prev) =>
      ({
        ...prev,
        minStart: DateTime.now()
          .set({ hour: 8, minute: 0, second: 0 })
          .toJSDate(),
      }));
  };
  const handleEndMinimum = () => {
    setValidDate((prev) =>
      ({
        ...prev,
        minEnd: DateTime.fromJSDate(formik.values[startName])
          .plus({ hour: minHourWorkshift })
          .toJSDate(),
      }));
  };

  const handleStartError = (reason: TimeValidationError) => {
    setError((prev) =>
      ({
        ...prev,
        startError:
        errorConfig[startName][
          reason as keyof (typeof errorConfig)[typeof startName]
        ],
      }));
  };

  const handleEndError = (reason: TimeValidationError) => {
    setError((prev) =>
      ({
        ...prev,
        endError:
        errorConfig[endName][
          reason as keyof (typeof errorConfig)[typeof endName]
        ],
      }));
  };

  useEffect(() => {
    handleStartMinimum();
    handleEndMinimum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values[startName]]);

  useEffect(() => {
    dispatch(setDisableAvailability(!error.endError && !error.startError));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <Box>
        <CustomTimePicker
          label={removeLabel ? null : startNameLabel ?? startName}
          horizontal={horizontal}
          onError={handleStartError}
          error={error.startError as string}
          formik={formik}
          variant={variant}
          placeholder={startPlaceholder}
          customHandler={customStartHandler}
          minTime={validateDate ? null : validDate.minStart}
          maxTime={
            validateDate
              ? null
              : DateTime.now()
                .set({ hour: 18, minute: 0, second: 0 })
                .toJSDate()
          }
          name={startName}
          customClass={removeMargin ? {} : genericClasses.addAvailabilityMargin}
          disabled={formik.values.allday || disabled}
          usePreFilledColors={usePreFilledColors}
        />
        <Box sx={horizontalErrorStyles.container}>
          <Box sx={horizontalErrorStyles.label} />
          <FormikError
            horizontal={horizontal}
            formik={formik}
            name={startName}
          />
        </Box>
      </Box>
      <Box>
        <CustomTimePicker
          label={removeLabel ? null : endNameLabel ?? endName}
          horizontal={horizontal}
          placeholder={endPlaceholder}
          minTime={validDate.minEnd}
          variant={variant}
          disabled={formik.values.allday || disabled}
          maxTime={
            validateDate
              ? null
              : DateTime.now()
                .set({ hour: 22, minute: 0, second: 0 })
                .toJSDate()
          }
          error={error.endError as string}
          onError={handleEndError}
          formik={formik}
          customClass={removeMargin ? {} : genericClasses.addAvailabilityMargin}
          name={endName}
          usePreFilledColors={usePreFilledColors}
        />
        <Box sx={horizontalErrorStyles.container}>
          <Box sx={horizontalErrorStyles.label} />
          <FormikError horizontal={horizontal} formik={formik} name={endName} />
        </Box>
      </Box>
    </>
  );
};
DateTimeRange.defaultProps = defaultProps;

export default DateTimeRange;
