import React, { memo, useRef, useState } from 'react';

import Calendar from 'react-calendar';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Box } from '@mui/system';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevroRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import moment from 'moment';

import { activeDate, setActiveDate } from '@pharmaplan/common';

import { useClickOutside } from '../../../../hooks/useClickOutside';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { MomentFormatOptions } from '../../../../helpers/Constants';

import useStyles from './style';

const YearCalendar = () => {
  const [popover, setPopover] = useState({
    key: 1,
    popoverState: false,
  });
  const calendarRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const classes = useStyles();

  const activeDateSelector = useAppSelector(activeDate);

  const {
    downChevronIcon,
    yearCalendarHidden,
    yearCalendarContainer,
    monthViewReorder,
    yearLabel,
  } = classes;

  const { shortMonthName } = MomentFormatOptions;
  const { key, popoverState } = popover;

  const calendarValue = new Date(activeDateSelector);
  const calendarClassName = clsx(yearCalendarContainer, !popoverState && yearCalendarHidden);

  const handlePopover = () => {
    setPopover((prevState) =>
      ({
        ...prevState,
        popoverState: !prevState.popoverState,
      }));
  };

  const handleClickOutside = () => {
    setPopover((prevState) =>
      ({
        popoverState: false,
        key: prevState.key + 1,
      }));
  };

  const handleMonthClick = (value: Date) => {
    dispatch(setActiveDate(value.toISOString()));
    handlePopover();
  };

  const formatMonth = (_: string, date: Date) =>
    moment(date).format(shortMonthName);

  const navigationLabel = ({ label }: { label: string }) =>
    (
      <Box component="div" className={yearLabel}>
        {label}
      </Box>
    );

  useClickOutside(calendarRef, () =>
    handleClickOutside());

  return (
    <Box component="div" ref={calendarRef}>
      <KeyboardArrowDown
        className={downChevronIcon}
        onClick={handlePopover}
      />

      <Calendar
        key={key}
        className={calendarClassName}
        view="year"
        value={calendarValue}
        formatMonth={formatMonth}
        tileClassName={monthViewReorder}
        onClickMonth={handleMonthClick}
        prevLabel={<ChevronLeftIcon />}
        nextLabel={<ChevroRightIcon />}
        next2Label={null}
        prev2Label={null}
        navigationLabel={navigationLabel}
        minDetail="year"
      />
    </Box>
  );
};

export default memo(YearCalendar);
