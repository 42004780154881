import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { FormikValues, useFormik } from 'formik';

import {
  adminActions,
  adminInfo,
  getAdminInfo,
  getLanguages,
  language,
  resetStatus,
  successSelector,
  updateAdminInfo,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import ContentContainer from '../../common/ContentContainer';
import ProfileSettings from '../../Profile/ProfileSettings';
import ProfileFormGenerator from '../../Profile/ProfileForm/ProfileFormGenerator';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { showSuccess } from '../Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../../localization';

import { adminProfileGeneralSection } from './helper';
import { AdminProfileValidator } from './Validator';
import useGetItems from '../../../hooks/useGetItems';
import useLanguage from '../../../hooks/useLanguge';

const AdminProfile = () => {
  const [selected, setSelected] = useState('general');
  const dispatch = useAppDispatch();

  const languages = useAppSelector(getLanguages);
  const currentLang = useAppSelector(language);
  const profileInfo = useAppSelector(adminInfo);

  const success = useAppSelector((state) =>
    successSelector([adminActions.updateAdminInfo], state));

  const { userName, email, languageId, phone } = profileInfo ?? {};
  const { getLanguageCode } = useGetItems();
  const { changeLang } = useLanguage();

  const handleSelection = (screen: string) => {
    setSelected(screen);
  };

  const onSubmit = (values: FormikValues) => {
    const { languageId: formikLanguageId, phone: formikPhone, userName: formikUserName } = values ?? {};

    dispatch(
      updateAdminInfo({
        languageId: formikLanguageId,
        phone: formikPhone,
        username: formikUserName,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      userName,
      email,
      languageId,
      phone,
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema: AdminProfileValidator(),
  });

  const { values } = formik ?? {};
  const { languageId: formikLanguageId } = values ?? {};

  useEffect(() => {
    if (success) {
      const languageCode = getLanguageCode(formikLanguageId);
      const isCurrentAndUserLangSame = languageCode === currentLang;

      if (!isCurrentAndUserLangSame) {
        changeLang(languageCode || currentLang);
      }

      showSuccess(dispatch, strings.savedSuccessfully);
      dispatch(getAdminInfo());
      dispatch(resetStatus());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    dispatch(getAdminInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid key={1} container spacing={2}>
      {/* Settigns list */}
      <Grid item xs={3} lg={2}>
        <ProfileSettings selected={selected} onClick={handleSelection} />
      </Grid>
      {/* Content List */}
      <Grid item xs={9} lg={10}>
        <ContentContainer>
          <ProfileFormGenerator
            formik={formik}
            items={adminProfileGeneralSection(languages).general.form()}
            title={adminProfileGeneralSection(languages).general.title}
          />
        </ContentContainer>
      </Grid>
    </Grid>
  );
};

export default AdminProfile;
