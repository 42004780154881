import { Step } from 'react-joyride';
import strings from '../localization';

const journeyStepsPharmacist = (): Step[] =>
  [
    {
      content: strings.profileBtn,
      target: '#avatarGuide',
      disableBeacon: true,
    },
    {
      content: strings.dashboardBtn,
      target: '#dashboard',
      disableBeacon: true,
    },
    {
      content: strings.myReplacementsBtn,
      target: '#myReplacements',
      disableBeacon: true,
    },
    {
      content: strings.reportsBtn,
      target: '#reports',
      disableBeacon: true,
    },
    {
      content: strings.availabilityBtn,
      target: '#availabilties',
      disableBeacon: true,
    },
    {
      content: strings.datePicker,
      target: '#calendarViewDropdown',
      disableBeacon: true,
    },
    {
      content: strings.todayBtn,
      target: '#today',
      disableBeacon: true,
    },
    {
      content: strings.searchBtn,
      target: '#filter',
      disableBeacon: true,
    },
    {
      content: strings.mapView,
      target: '#map',
      disableBeacon: true,
    },
  ];

const journeyStepsPharmacy = (): Step[] =>
  [
    {
      content: strings.profileBtnPharmacy,
      target: '#avatarGuide',
      disableBeacon: true,
    },
    {
      content: strings.dashboardBtn,
      target: '#dashboard',
      disableBeacon: true,
    },
    {
      content: strings.myReplacementsBtn,
      target: '#myReplacements',
      disableBeacon: true,
    },
    {
      content: strings.reportsBtnPharmacy,
      target: '#reports',
      disableBeacon: true,
    },
    {
      content: strings.myWorkShifts,
      target: '#availabilties',
      disableBeacon: true,
    },
    {
      content: strings.datePicker,
      target: '#calendarViewDropdown',
      disableBeacon: true,
    },
    {
      content: strings.todayBtn,
      target: '#today',
      disableBeacon: true,
    },
    {
      content: strings.legendGuide,
      target: '#legendContainer',
      disableBeacon: true,
    },
  ];

const journeyStepsButtons = () =>
  ({
    back: strings.backGuide,
    close: strings.close,
    last: strings.end,
    next: strings.next,
    skip: strings.skip,
  });

export { journeyStepsPharmacist, journeyStepsPharmacy, journeyStepsButtons };
