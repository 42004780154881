import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import { ServiceTypes } from '@pharmaplan/common';
import { Constants, TextFieldVariants } from '../../../../helpers/Constants';
import strings from '../../../../localization';
import genericClasses from '../../../../theme/GenericClasses';
import styles from './style';

export const adminEditFormConfig = () => {
  const isSelfService = ServiceHandler.getService() === ServiceTypes.self;

  const common = [
    {
      label: strings.noOfDaysToRatePharmacist,
      customTextFieldStyle: genericClasses.customFilledInput,
      variant: TextFieldVariants.filled,
      type: Constants.formInputType.text,
      name: 'ratePharmacistDays',
      xs: 6,
    },
    {
      label: strings.minHoursWorkshift,
      type: Constants.formInputType.text,
      customTextFieldStyle: genericClasses.customFilledInput,
      variant: TextFieldVariants.filled,
      name: 'minHourWorkshift',
      xs: 6,
    },
    {
      label: strings.formatString(
        strings.defaultHourlyRate,
        strings.pharmacist,
      ),
      type: Constants.formInputType.text,
      name: 'pharmacistRate',
      xs: 6,
      suffix: '$',
      maxLength: 6,
      customTextFieldStyle: genericClasses.customFilledInput,
      variant: TextFieldVariants.filled,
    },
    {
      label: strings.formatString(strings.defaultHourlyRate, strings.pharmacy),
      type: Constants.formInputType.text,
      name: 'pharmacyRate',
      xs: 6,
      suffix: '$',
      maxLength: 6,
      customTextFieldStyle: genericClasses.customFilledInput,
      variant: TextFieldVariants.filled,
    },
    {
      label: strings.formatString(
        strings.defaultHourlyRate,
        `${strings.tech}.`,
      ),
      type: Constants.formInputType.text,
      name: 'technicianRate',
      xs: 6,
      suffix: '$',
      maxLength: 6,
      customTextFieldStyle: genericClasses.customFilledInput,
      variant: TextFieldVariants.filled,
    },
  ];

  const title = isSelfService ? strings.pharmagap : strings.pharmaPlan;

  const formConfig = () =>
    (isSelfService
      ? [
        ...common,
        {
          label: strings.noCancellationPeriod,
          type: Constants.formInputType.text,
          customTextFieldStyle: genericClasses.customFilledInput,
          variant: TextFieldVariants.filled,
          name: 'noCancellationPeriod',
          xs: 6,
          suffix: strings.days,
        },
        {
          label: strings.requestPendingPeriod,
          type: Constants.formInputType.text,
          customTextFieldStyle: genericClasses.customFilledInput,
          variant: TextFieldVariants.filled,
          name: 'bookingStateExpiration',
          xs: 6,
          suffix: strings.hrs,
        },
        {
          label: strings.pharmaPlanFees,
          type: Constants.formInputType.text,
          customTextFieldStyle: genericClasses.customFilledInput,
          variant: TextFieldVariants.filled,
          name: 'pharmaPlanPercentFees',
          xs: 6,
          suffix: '%',
        },
        {
          label: strings.cancellationFees,
          type: Constants.formInputType.text,
          customTextFieldStyle: genericClasses.customFilledInput,
          variant: TextFieldVariants.filled,
          name: 'cancellationPercentFees',
          xs: 6,
          suffix: '%',
        },
        {
          label: strings.freeCancellationPeriod,
          type: Constants.formInputType.text,
          customTextFieldStyle: genericClasses.customFilledInput,
          variant: TextFieldVariants.filled,
          name: 'freeCancellationPeriod',
          xs: 6,
          suffix: strings.hrs,
        },
        {
          label: strings.pharmacistReminderPost,
          type: Constants.formInputType.text,
          customTextFieldStyle: genericClasses.customFilledInput,
          variant: TextFieldVariants.filled,
          name: 'reminder1After',
          xs: 6,
          suffix: strings.hrs,
        },
        {
          label: strings.pharmacistReminderPre,
          type: Constants.formInputType.text,
          customTextFieldStyle: genericClasses.customFilledInput,
          variant: TextFieldVariants.filled,
          name: 'reminder2Before',
          xs: 6,
          suffix: strings.hrs,
        },
      ]
      : [...common]);

  return [
    {
      key: 0,
      title: strings.formatString(strings.customSettings, title),
      form: formConfig,
    },
    {
      key: 1,
      title: strings.formatString(
        strings.customSettings,
        strings.customerServiceRepresentative,
      ),
      form: () =>
        [
          {
            label: strings.representativeName,
            type: Constants.formInputType.text,
            customTextFieldStyle: genericClasses.customFilledInput,
            variant: TextFieldVariants.filled,
            name: 'representativeName',
            xs: 6,
          },
          {
            label: strings.representativeEmail,
            type: Constants.formInputType.text,
            customTextFieldStyle: genericClasses.customFilledInput,
            variant: TextFieldVariants.filled,
            name: 'representativeEmail',
            xs: 6,
          },
          {
            label: strings.representativePhone,
            type: Constants.formInputType.text,
            customTextFieldStyle: genericClasses.customFilledInput,
            variant: TextFieldVariants.filled,
            name: 'representativePhone',
            xs: 6,
          },
          {
            label: strings.phoneExt,
            type: Constants.formInputType.text,
            customTextFieldStyle: genericClasses.customFilledInput,
            variant: TextFieldVariants.filled,
            name: 'representativePhoneExt',
            xs: 6,
          },
        ],
    },
    {
      key: 3,
      title: strings.formatString(strings.customSettings, strings.salesforce),
      form: () =>
        [
          {
            label: strings.salesForceUsername,
            customTextFieldStyle: genericClasses.customFilledInput,
            variant: TextFieldVariants.filled,
            type: Constants.formInputType.text,
            name: 'salesForceUserName',
            xs: 6,
          },
          {
            label: strings.salesForcePWD,
            type: Constants.formInputType.text,
            customTextFieldStyle: genericClasses.customFilledInput,
            variant: TextFieldVariants.filled,
            name: 'salesForcePassword',
            xs: 6,
          },
          {
            label: strings.salesForceSecurityToken,
            type: Constants.formInputType.text,
            customTextFieldStyle: genericClasses.customFilledInput,
            variant: TextFieldVariants.filled,
            name: 'salesForceSecurityToken',
            xs: 6,
          },
          {
            label: strings.urlOfSalesForce,
            type: Constants.formInputType.text,
            customTextFieldStyle: genericClasses.customFilledInput,
            variant: TextFieldVariants.filled,
            name: 'salesForceURL',
            xs: 6,
          },
          {
            label: strings.integrationSalesForce,
            type: Constants.formInputType.checkbox,
            name: 'salesForceActive',
            id: 'salesForceActive',
            xs: 6,
            customClass: styles.checkbox,
            labelClass: styles.checkboxText,
          },
        ],
    },
    {
      key: 4,
      title: strings.formatString(strings.customSettings, strings.more),
      form: () =>
        [
          {
            label: strings.sendAllEmailsBCC,
            type: Constants.formInputType.checkbox,
            name: 'envoiceBCCAdmin',
            id: 'envoiceBCCAdmin',
            xs: 6,
            customClass: styles.moreSettingsCheckbox,
            labelClass: styles.checkboxText,
          },
          {
            label: strings.showAllBookingDetails,
            type: Constants.formInputType.checkbox,
            name: 'showBookingDetails',
            id: 'showBookingDetails',
            xs: 6,
            customClass: styles.moreSettingsCheckbox,
            labelClass: styles.checkboxText,
          },
        ],
    },
  ];
};
