import React from 'react';

import DynamicTable from '../../DynamicTable';
import usePharmacyList from '../../../hooks/Admin/PharmacyList/usePharmacyList';

import PharmacyListHeader from './PharmacyListHeader';
import useUserNotFound from '../../../hooks/useUserNotFound';

const PharmacyList = () => {
  const {
    table,
    page,
    handlePagination,
    selectedTab,
    setSelectedTab,
    handleReset,
    formik,
    loadSuccess,
    rowData,
    totalCount,
  } = usePharmacyList();

  const { length } = rowData;
  const { showUserNotFound } = useUserNotFound(loadSuccess, !!length);

  return (
    <DynamicTable
      table={table}
      loadSuccess={loadSuccess}
      totalCount={totalCount}
      page={page}
      handlePagination={handlePagination}
      emptyContainerComponent={showUserNotFound()}
      customHeader={(
        <PharmacyListHeader
          selectedTab={selectedTab}
          setSelected={setSelectedTab}
          formik={formik}
          handleReset={handleReset}
        />
      )}
      showHeader={false}
    />
  );
};

export default PharmacyList;
