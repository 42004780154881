import React, { Fragment, useEffect, useState } from 'react';

import { Close } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { FormikValues, useFormik } from 'formik';

import {
  Language,
  bookedRating,
  getBookedRating,
  getRatingItems,
  language,
  rate,
  ratingItems,
} from '@pharmaplan/common';
import { ReactComponent as FavouriteActive } from '@pharmaplan/common/assets/icons/favourtieActive.svg';
import { ReactComponent as FavouriteNotActive } from '@pharmaplan/common/assets/icons/favouriteNotActive.svg';

import CustomRatingStar from '../../common/CustomRatingStar/CustomRatingStar';
import CustomButton from '../../common/CustomButton/CustomButton';
import CustomTextField from '../../common/CustomTextField';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';
import { resetDialog } from '../../../reducers/dialogReducer';

import styles from './styles';

interface IPharmacistRatingProps {
  pharmacistId: string;
  pharmacistName: string;
  bookingId: string;
}

const PharmacistRating = ({
  pharmacistId,
  pharmacistName,
  bookingId,
}: IPharmacistRatingProps) => {
  const dispatch = useAppDispatch();
  const ratingData = useAppSelector(ratingItems);
  const userLanguage = useAppSelector(language);
  const bookedRatingData = useAppSelector(bookedRating);

  const { data, comment: bookedComment } = bookedRatingData ?? {};
  const doesDataExist = data.length;
  const isEN = userLanguage === Language.en;
  const { pharmacistRatingItems } = ratingData ?? {};

  const [favState, setFavState] = useState(false);
  const [ratingLevel, setRatingLevel] = useState<{ [key: string]: number }>({});
  const style = styles(favState);

  const handleSubmit = (values: FormikValues) => {
    const levels = pharmacistRatingItems.map((item) => {
      const { pharmacistRatingItemId } = item ?? {};
      return {
        pharmacistRatingItemId,
        level: ratingLevel[pharmacistRatingItemId] ?? 0,
      };
    });

    const { comment } = values ?? {};

    dispatch(
      rate({
        bookingId: bookingId ?? '',
        levels: [...levels],
        favorite: favState,
        comment,
        pharmacistId,
      }),
    );
    dispatch(resetDialog());
  };

  const formik = useFormik({
    initialValues: {
      comment: doesDataExist ? bookedComment : '',
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    dispatch(resetDialog());
  };

  const onFavoriteClick = () => {
    setFavState((prev) =>
      !prev);
  };

  useEffect(() => {
    setRatingLevel(
      data.reduce((acc: Record<string, number>, cur) => {
        acc[cur?.pharmacistRatingItemId ?? ''] = cur?.level ?? 0;
        return acc;
      }, {}),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookedRatingData]);

  useEffect(() => {
    dispatch(getRatingItems(pharmacistId));
    dispatch(getBookedRating(bookingId ?? ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFavState(ratingData.favorite);
  }, [ratingData.favorite]);

  return (
    <Box sx={style.ratingContainer}>
      <Box sx={style.closeContainer}>
        <IconButton size="small" sx={style.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={style.ratingTitle}>
        <Box>
          <Box>
            <Typography sx={style.title}>
              {strings.myPharmacistRating}
            </Typography>
          </Box>
          <Typography sx={style.title}>
            [
            {pharmacistName}
            ]
          </Typography>
        </Box>

        <Box>
          <CustomButton
            variant="outlined"
            startIcon={
              favState ? (
                <FavouriteActive style={style.buttonIcon} />
              ) : (
                <FavouriteNotActive style={style.buttonIcon} />
              )
            }
            label={strings.favourite}
            customButtonStyle={style.isfavoriteButton}
            onClick={onFavoriteClick}
          />
        </Box>
      </Box>

      {pharmacistRatingItems.map((item, index) => {
        const { pharmacistRatingItemId, englishName, frenchName } = item ?? {};
        const isLastElement = index !== pharmacistRatingItems.length - 1;
        return (
          <Fragment key={item.pharmacistRatingItemId}>
            <CustomRatingStar
              key={pharmacistRatingItemId}
              text={isEN ? englishName : frenchName}
              count={5}
              ratingLevel={ratingLevel[pharmacistRatingItemId] ?? 0}
              handleClick={(starIndex: number) =>
                setRatingLevel((prev) =>
                  ({
                    ...prev,
                    [pharmacistRatingItemId]: starIndex,
                  }))}
            />
            {isLastElement && <Divider />}
          </Fragment>
        );
      })}
      <CustomTextField
        variant="outlined"
        formik={formik}
        id="notes"
        label={strings.comment}
        placeholder={' '}
        name="comment"
        multiline
      />
      <Box sx={style.buttonContainer}>
        <CustomButton
          customClass={style.button}
          label={strings.save}
          onClick={formik.handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default PharmacistRating;
