const classes = {
  rateButton: {
    maxHeight: '28px',
    maxWidth: '81px',
    '.MuiButton-startIcon': {
      width: '12px',
      height: '12px',
      alignItems: 'center',
    },
  },
};

export default classes;
