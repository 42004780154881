import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { FormikValues, useFormik } from 'formik';
import { GridSize, SxProps, Theme } from '@mui/system';
import { Schema } from 'yup';

import { formGenerator } from '../../../helpers/formGenerator';
import CustomButton from '../../common/CustomButton';

import styles from './styles';

export interface IInputModalConfig {
  key: string;
  labelXs: GridSize;
  customTextFieldStyle?: SxProps<Theme>;
  customTextFieldContainer?: SxProps<Theme>;
  mask?: string;
  maxLength?: number;
  customCheckboxStyle?: SxProps<Theme>;
  gridLabelClass?: SxProps<Theme>;
  fieldXs: GridSize;
  gridLabel?: string;
  name: string;
  customClass?: SxProps<Theme>;
  style?: Record<string, number | string>;
  label?: string;
  type: string;
  multiline?: boolean;
  variant?: string;
  disabled?: boolean;
  labelClass?: Record<string, number | string>;
}

interface IInputModal {
  config: Array<IInputModalConfig>;
  initialValues: Record<string, string | boolean>;
  submitLabel: string;
  onSubmit: (values: FormikValues) => void;
  validator?: Schema;
}

const InputModal: FC<IInputModal> = ({
  config,
  initialValues,
  onSubmit,
  submitLabel,
  validator,
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema: validator,
  });

  return (
    <Box sx={styles.container}>
      <Grid container>
        {config.map((item) => {
          const { key, labelXs, fieldXs, gridLabel, gridLabelClass } = item ?? {};
          return (
            <Grid key={key} sx={styles.itemContainer} container xs={12}>
              {gridLabel && (
                <Grid sx={styles.labelContainer} item xs={labelXs}>
                  <Typography
                    sx={[styles.label, gridLabelClass] as SxProps<Theme>}
                  >
                    {gridLabel}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={fieldXs}>
                {formGenerator(item, formik)}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Box sx={styles.buttonContainer}>
        <CustomButton label={submitLabel} onClick={formik.handleSubmit} />
      </Box>
    </Box>
  );
};
export default InputModal;
