import React, { useEffect } from 'react';
import {
  ConfirmationTypes,
  pharmacistActions,
  pharmacyActions,
  successOrSelector,
} from '@pharmaplan/common';
import { useAppDispatch } from './useAppDispatch';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import { resetDialog, setDialog } from '../reducers/dialogReducer';
import { enableAddCalendarEvent } from '../reducers/mainCalendarReducer';
import { useAppSelector } from './useAppSelector';

const useContinueCreation = () => {
  const dispatch = useAppDispatch();
  const isSuccess = useAppSelector((state) =>
    successOrSelector(
      [pharmacistActions.createAvailability, pharmacyActions.createWorkshift],
      state,
    ));

  const closeDialog = () => {
    dispatch(resetDialog());
  };

  const negative = () => {
    dispatch(enableAddCalendarEvent(false));
    closeDialog();
  };

  const showContinueModal = () => {
    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            confirmAction={closeDialog}
            customClose={negative}
            type={ConfirmationTypes.continueCreation}
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  useEffect(() => {
    if (isSuccess) {
      showContinueModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
};

export default useContinueCreation;
