/* eslint-disable @typescript-eslint/no-empty-function */
import { IAdminListingResponseData, TimeFormat } from '@pharmaplan/common';
import {
  adminTypeMap,
  Constants,
  DynamicTableCell,
} from '../../../helpers/Constants';
import editIcon from '../../../assets/svg/updateNoteIcon.svg';
import { getDayMonthDateYear, getHourMinute } from '../../Reports/helpers';
import strings from '../../../localization';

const { text, button, bar } = DynamicTableCell;
const handleString = (date: string | null, val: string) => {
  if (date) {
    return val;
  }
  return '-';
};

export const adminsListingHeaders = () =>
  [
    { key: '2', label: strings.name },
    { key: '1', label: strings.email },
    { key: '3', label: strings.userType },
    {
      key: '4',
      label: strings.formatString(
        strings.dateTimeSuffix,
        strings.lastActivity,
      ) as string,
    },
    {
      key: '5',
      label: strings.formatString(
        strings.dateTimeSuffix,
        strings.lastLogin,
      ) as string,
    },
    { key: '6', label: strings.action },
    { key: '7', label: ' ' },
  ];

export const activeAdminListingRows = (
  data: Array<IAdminListingResponseData>,
  timeFormat: TimeFormat,
  disapprove: (pharmacyId: string) => () => void,
  edit: (userId: string) => () => void,
) =>
  data?.map((item) => {
    const {
      email,
      userName,
      userId,
      lastLoginDate,
      lastActivityDate,
      userType,
    } = item;

    const loginDate = handleString(
      lastLoginDate,
      `${getDayMonthDateYear(lastLoginDate ?? '')} | ${getHourMinute(
        lastLoginDate ?? '',
        timeFormat,
      )}`,
    );

    const activityDate = handleString(
      lastActivityDate,
      `${getDayMonthDateYear(lastActivityDate ?? '')} | ${getHourMinute(
        lastActivityDate ?? '',
        timeFormat,
      )}`,
    );

    return {
      key: userId,
      data: [
        { key: '2', value: userName, type: text, maxWidth: 200 },
        { key: '1', value: email, type: text, maxWidth: 200 },
        { key: '3', value: adminTypeMap[userType], type: text },
        { key: '4', value: activityDate, type: text },
        { key: '5', value: loginDate, type: text },
        {
          key: '6',
          value: {
            label: strings.deactivate,
            variant: Constants.variant.text,
            color: 'error',
            handlePress: disapprove(userId),
          },
          show: true,
          maxWidth: 10,
          type: button,
        },
        {
          key: '7',
          value: [
            {
              key: '1',
              icon: editIcon,
              tooltip: strings.edit,
              onClick: edit(userId),
            },
          ],
          type: bar,
        },
      ],
    };
  });

export const inactiveAdminListingRows = (
  data: Array<IAdminListingResponseData>,
  timeFormat: TimeFormat,
  activate: (pharmacyId: string) => () => void,
) =>
  data?.map((item) => {
    const {
      email,
      userName,
      userId,
      lastLoginDate,
      lastActivityDate,
      userType,
    } = item;

    const loginDate = handleString(
      lastLoginDate,
      `${getDayMonthDateYear(lastLoginDate ?? '')} | ${getHourMinute(
        lastLoginDate ?? '',
        timeFormat,
      )}`,
    );

    const activityDate = handleString(
      lastActivityDate,
      `${getDayMonthDateYear(lastActivityDate ?? '')} | ${getHourMinute(
        lastActivityDate ?? '',
        timeFormat,
      )}`,
    );

    return {
      key: userId,
      data: [
        { key: '2', value: userName, type: text, maxWidth: 200 },
        { key: '1', value: email, type: text, maxWidth: 200 },
        { key: '3', value: adminTypeMap[userType], type: text },
        { key: '4', value: loginDate, type: text },
        { key: '5', value: activityDate, type: text },
        {
          key: '6',
          value: {
            label: strings.activate,
            variant: Constants.variant.text,
            handlePress: activate(userId),
          },
          show: true,
          maxWidth: 10,
          type: button,
        },
      ],
    };
  });
