import {
  adminActions,
  adminCreatePharmacistCapacity,
  adminGetPharmacistCapacityList,
  adminPharmacistCapacityList,
  IAdminCreatePharmacistCapacityApiParams,
  PermissionsOfAdmin,
} from '@pharmaplan/common';
import { FormikValues } from 'formik';
import React, { useEffect } from 'react';
import useConfigurationsSuccess from '../../../hooks/Admin/useConfigurationsSuccess';
import usePublishConfigurations from '../../../hooks/Admin/usePublishConfigurations';
import usePharmacistCapacities from '../../../hooks/PharmacistCapacities/usePharmacistCapacities';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';
import { setDialog } from '../../../reducers/dialogReducer';
import CreateNew from '../../common/CreateNew';
import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';
import InputModal from '../../Modals/InputModal';
import {
  addNewPharmacistCapacityForm,
  canCreateNew,
  pharmacistCapacityHeaders,
  pharmacistCapacityMap,
} from './helper';
import Validator from './validator';
import useDeleteConfirmation from '../../../hooks/Admin/useDeleteConfirmation';
import { DeleteConfirmationType } from '../../../helpers/Constants';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';

const initialValues = {
  from: '',
  to: '',
  published: false,
};

const { createPharmacistCapacity: createAction, updatePharmacistCapacity } = adminActions;

const PharmacistCapacities = () => {
  const dispatch = useAppDispatch();

  const { can } = useAdminPermissions();

  const canChangeParameters = can(PermissionsOfAdmin.ChangeParameters);
  const canDelete = can(PermissionsOfAdmin.Delete);

  useConfigurationsSuccess({
    addAction: createAction,
    editAction: updatePharmacistCapacity,
  });

  const pharmacistCapacityList = useAppSelector(adminPharmacistCapacityList);
  const { handleUpdate } = usePharmacistCapacities();
  const { showDeleteConfirmation } = useDeleteConfirmation(
    DeleteConfirmationType.pharmacistCapacities,
  );
  const {
    setPublishedRecords,
    handlePublish,
    mapPublishedRecords,
    publishedRecords,
  } = usePublishConfigurations();

  useEffect(() => {
    const pharmacistCapacityPublishMap = mapPublishedRecords(
      pharmacistCapacityList,
      'pharmacistCapacityId',
      'published',
    );
    setPublishedRecords(pharmacistCapacityPublishMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacistCapacityList]);

  useEffect(() => {
    dispatch(adminGetPharmacistCapacityList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPharmacistCapacity = (values: FormikValues) => {
    dispatch(
      adminCreatePharmacistCapacity(
        values as IAdminCreatePharmacistCapacityApiParams,
      ),
    );
  };

  const createModal = () => {
    dispatch(
      setDialog({
        Component: (
          <InputModal
            onSubmit={createPharmacistCapacity}
            initialValues={initialValues}
            submitLabel={strings.create}
            config={addNewPharmacistCapacityForm}
            validator={Validator()}
          />
        ),
        heading: {
          title: strings.addNew,
        },
        showCloseButton: true,
      }),
    );
  };

  const table: IDynamicTableObject = {
    title: strings.pharmacistCapacities,
    rows: pharmacistCapacityMap(
      pharmacistCapacityList,
      publishedRecords,
      handlePublish,
      handleUpdate,
      showDeleteConfirmation,
      canChangeParameters,
      canDelete,
    ),
    headers: pharmacistCapacityHeaders(),
    headerBar: canCreateNew(canChangeParameters, {
      Component: <CreateNew label={strings.createNew} onClick={createModal} />,
      key: '1',
    }),
  };

  return (
    <DynamicTable
      customContainerStyle={{ minHeight: '75vh' }}
      hidePagination
      table={table}
      loadSuccess
    />
  );
};

export default PharmacistCapacities;
