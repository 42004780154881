import React, { useEffect, useState } from 'react';

import { FormikValues, useFormik } from 'formik';

import {
  adminActions,
  adminResetValue,
  getPharmacistDetailsSearch,
  resetStatus,
  successOrSelector,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';
import useSelectedTabs from '../../../hooks/Admin/useSelectedTabs';
import { PharmacistListTabTypes } from '../../../helpers/Constants';
import usePharmacistListTabs from '../../../hooks/PharmacistList/usePharmacistListTabs';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { showSuccess } from '../Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../../localization';

import PharmacistListHeader from './PharmacistListHeader';
import useUserNotFound from '../../../hooks/useUserNotFound';

const detailsKey = 'pharmacistDetailsSearch';
const { activatePharmacist, showPharmacist } = adminActions;
const successActions = [activatePharmacist, showPharmacist];

export interface IPharmacistListActionsParams {
  pharmacistId: string;
  pharmacistName: string;
}

const initialValues = { userText: '', capacityIds: [], softwareIds: [] };
const { activePharmacists, detailsSearch } = PharmacistListTabTypes;

const PharmacistList = () => {
  const dispatch = useAppDispatch();
  const success = useAppSelector((state) =>
    successOrSelector(successActions, state));

  const [page, setPage] = useState(1);
  const { selectedTabs, handleTabClick } = useSelectedTabs(activePharmacists);

  const { headers, rows, api, data, loadSuccess } = usePharmacistListTabs(selectedTabs);
  const {
    totalCount,
    data: { length },
  } = data ?? {};

  const { showUserNotFound } = useUserNotFound(
    loadSuccess,
    !!length,
    strings.emptyIncompatiblePrompt,
  );

  const table: IDynamicTableObject = {
    headerBar: [],
    headers,
    rows,
  };

  const handleApi = (values: any, inPage: number) => {
    const { softwareIds, capacityIds, userText } = values;

    if (selectedTabs === detailsSearch) {
      dispatch(
        getPharmacistDetailsSearch({
          softwareIds,
          capacityIds,
          paging: { page: inPage },
        }),
      );
    } else {
      dispatch(api({ userText: userText.trim(), page: inPage }));
    }
  };

  const handleSubmit = (values: FormikValues) => {
    setPage(1);
    handleApi(values, 1);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const { values } = formik ?? {};

  const handleReset = () => {
    formik.resetForm();
    setPage(1);
    handleApi(initialValues, 1);
  };

  const defaultPageLoad = () => {
    formik.resetForm();
    setPage(1);
    if (selectedTabs !== detailsSearch) {
      handleApi(initialValues, 1);
    } else {
      dispatch(adminResetValue([detailsKey]));
      dispatch(resetStatus([adminActions.getAdminPharmacistDetailsSearch]));
    }
  };

  useEffect(() => {
    defaultPageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabs]);

  useEffect(() => {
    if (success) {
      showSuccess(dispatch, strings.actionCompletedSuccessfully);
      handleApi(values, 1);
      dispatch(resetStatus(successActions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handlePagination = (_: unknown, selectedPage: number) => {
    const goToPage = selectedPage + 1;
    setPage(goToPage);
    handleApi(values, goToPage);
  };

  return (
    <DynamicTable
      table={table}
      loadSuccess={loadSuccess}
      showHeader={false}
      handlePagination={handlePagination}
      page={page}
      totalCount={totalCount}
      emptyContainerComponent={showUserNotFound()}
      customHeader={(
        <PharmacistListHeader
          formik={formik}
          selectedTab={selectedTabs}
          handleTabClick={handleTabClick}
          handleReset={handleReset}
        />
      )}
    />
  );
};

export default PharmacistList;
