import React, { useEffect } from 'react';

import 'react-big-calendar/lib/sass/styles.scss';
import { Settings } from 'luxon';

import {
  Language,
  getLanguagesInfo,
  language,
  preLoginLang,
  setPreLoginLangWeb,
} from '@pharmaplan/common';
import actionTracker from '@pharmaplan/common/actionTracker';

import { Toaster } from 'react-hot-toast';
import LogoutHandler from '@pharmaplan/common/helpers/LogoutHandler';
import AppRouter from './AppRouter/AppRouter';
import Loader from './components/Loader/Loader';
import { useAppSelector } from './hooks/useAppSelector';
import { useAppDispatch } from './hooks/useAppDispatch';
import useLanguage from './hooks/useLanguge';
import useErrorHandler from './hooks/useErrorHandler';
import { isLoadingSelector } from './selectors/statusSelector';
import strings from './localization';

import { getBrowserLang } from './helpers/Functions';
import useLogoutCheck from './hooks/useLogoutCheck';
import './App.css';
import './Firebase';

const { en } = Language;

const App = () => {
  const dispatch = useAppDispatch();
  const browserLang = getBrowserLang();
  const { cookies } = useLanguage();
  useErrorHandler();

  const lang = useAppSelector(language);
  const isUserLoggedIn = LogoutHandler.isUserLoggedIn();
  const preLogin = useAppSelector(preLoginLang);
  const load = useAppSelector((state) =>
    isLoadingSelector(actionTracker, state));

  const { language: cookieLanguage } = cookies ?? {};
  const userLang = isUserLoggedIn ? lang : preLogin;
  Settings.defaultLocale = userLang;

  useEffect(() => {
    dispatch(setPreLoginLangWeb(cookieLanguage || browserLang));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieLanguage]);

  useEffect(() => {
    dispatch(getLanguagesInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLogoutCheck();

  return (
    <>
      <Loader show={load} />
      {strings.setLanguage(userLang || en)}
      <Toaster />
      <div className="App">
        <AppRouter />
      </div>
    </>
  );
};

export default App;
