/* eslint-disable react/require-default-props */
import React from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid } from '@mui/material';
import { setMultipleAddEvents } from '../../../reducers/mainCalendarReducer';
import classes from './style';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { getMultipleEventAdd } from '../../../selectors/calendarSelector';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';

const CustomSwitch = () => {
  const enableAdd = useAppSelector(getMultipleEventAdd);

  const dispatch = useAppDispatch();
  return (
    <Grid sx={classes.customSliderContainer} container>
      <FormControlLabel
        sx={classes.label}
        control={(
          <Switch
            checked={enableAdd}
            onChange={(e) =>
              dispatch(setMultipleAddEvents(e.target.checked))}
          />
        )}
        label={strings.multiple}
      />
    </Grid>
  );
};

export default CustomSwitch;
