/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getProInfo, proInfo, updateProInfo } from '@pharmaplan/common';
import { userInitialState } from '@pharmaplan/common/reducers/userReducer';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { ProInfoValidator } from '../../FormValidation';
import ProfileForm from '../../ProfileForm';
import { ICommonProfile } from '../General/General';

const ProfessionalInfo = ({ setSelected }: ICommonProfile) => {
  const dispatch = useAppDispatch();
  const info = useAppSelector(proInfo);
  const getMinusCurrentYear = (year: number) =>
    new Date().getFullYear() - year;
  const formik = useFormik({
    initialValues: { ...userInitialState?.proInfo },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: (values) =>
      dispatch(updateProInfo(values)),
    enableReinitialize: true,
    validationSchema: ProInfoValidator(),
  });

  useEffect(() => {
    formik.setFieldValue(
      'yearsOfExperience',
      getMinusCurrentYear(parseInt(formik.values.graduationYear, 10)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.graduationYear]);

  useEffect(() => {
    dispatch(getProInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik.setValues({
      ...info,
      // @ts-ignore
      englishLevel: info.englishLevel.toString(),
      // @ts-ignore
      frenchLevel: info.frenchLevel.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  return (
    <ProfileForm
      setSelected={setSelected}
      selected="professionalInfo"
      formik={formik}
    />
  );
};

export default ProfessionalInfo;
