import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  successOrSelector,
  pharmacistActions,
  getReplacementPDF,
  resetStatus,
  TypeOfUser,
  IReplacementsData,
  IPharmacyReplacementData,
  getPharmacyReplacementPdf,
  pharmacyActions,
  getPharmacyReplacementsWeb,
  ITEMS_PER_PAGE,
  getPharmacistReplacementsWeb,
  ToUserTypings,
} from '@pharmaplan/common';
import { useNavigate } from 'react-router-dom';
import DynamicTable from '../DynamicTable';
import strings from '../../localization';
import AdsContainer from '../common/AdsContainer';
import newReplacmentIcon from '../../assets/svg/newReplacmentIcon.svg';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { downloadPDF, setPageTitle } from '../../helpers/Functions';
import useUser from '../../hooks/useUser';
import ResponsiveClasses from '../../theme/ResponsiveClasses';

import {
  pharmacistReplacementMapper,
  pharmacyReplacementMapper,
} from './helpers';
import { resetDialog } from '../../reducers/dialogReducer';
import useGetItems from '../../hooks/useGetItems';
import useSelfService from '../../hooks/useSelfService';

const successActions = [
  pharmacyActions.addIncompatible,
  pharmacyActions.removeIncompatible,
];

const MyReplacements = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getImage } = useGetItems();
  const { isSelfService } = useSelfService();

  const loadSuccess = useAppSelector((state) =>
    successOrSelector(
      [pharmacistActions.replacementsWeb, pharmacyActions.replacementsWeb],
      state,
    ));

  const pdfSuccess = useAppSelector((state) =>
    successOrSelector(
      [pharmacistActions.getReplacementPDF, pharmacyActions.replacementsPdf],
      state,
    ));

  const success = useAppSelector((state) =>
    successOrSelector(successActions, state));

  const { replacements: repl, pdf, userType } = useUser();

  const [page, setPage] = useState(1);

  const currentIndex = (page - 1) * ITEMS_PER_PAGE;

  const headers = isSelfService ? [
    { key: '0', label: '#' },
    { key: '1', label: strings.name },
    { key: '2', label: strings.role },
    { key: '3', label: strings.totalReplacements, isCenterAligned: true },
    { key: '4', label: strings.totalHours, isCenterAligned: true },
    { key: '5', label: strings.favourite },
    { key: '6', label: strings.compatibility },
    { key: '7', label: strings.action },
  ] : [{ key: '0', label: '#' },
    { key: '1', label: strings.name },
    { key: '2', label: strings.role },
    { key: '3', label: strings.totalReplacements, isCenterAligned: true },
    { key: '4', label: strings.totalHours, isCenterAligned: true },
    { key: '5', label: strings.favourite },
    { key: '6', label: strings.action }];

  const userConfig = ToUserTypings({
    [TypeOfUser.pharmacist]: {
      title: strings.pharmaciesServed,
      headers: [
        { key: '0', label: '#' },
        { key: '1', label: strings.banner },
        { key: '2', label: strings.pharmacy },
        { key: '3', label: strings.favourite },
        { key: '4', label: strings.action },
      ],
      rows: pharmacistReplacementMapper(
        repl.data as IReplacementsData[],
        navigate,
        currentIndex,
        getImage,
      ),
      request: getPharmacistReplacementsWeb,
      pdfRequest: getReplacementPDF,
    },
    [TypeOfUser.pharmacy]: {
      title: strings.formatString(
        strings.myReplacementUser,
        strings.pharmacistsLower,
      ),
      headers,
      rows: pharmacyReplacementMapper(
        repl.data as IPharmacyReplacementData[],
        navigate,
        currentIndex,
      ),
      request: getPharmacyReplacementsWeb,
      pdfRequest: getPharmacyReplacementPdf,
    },
  });

  const Table = {
    title: userConfig[userType].title as string,
    headers: userConfig[userType].headers,
    headerBar: [
      {
        icon: newReplacmentIcon,
        key: '1',
        onClick: () =>
          dispatch(userConfig[userType].pdfRequest()),
      },
    ],
    rows: userConfig[userType].rows,
  };

  const handlePagination = (_: unknown, selectedPage: number) => {
    setPage(selectedPage + 1);
    dispatch(userConfig[userType].request(selectedPage + 1));
  };

  useEffect(() => {
    if (pdfSuccess) {
      downloadPDF(pdf.pdfContent, 'replacements.pdf');
      dispatch(
        resetStatus([
          pharmacistActions.getReplacementPDF,
          pharmacyActions.replacementsPdf,
        ]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfSuccess, pdf.pdfContent]);

  useEffect(() => {
    setPageTitle(strings.myPharmacies);
    dispatch(userConfig[userType].request(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(getPharmacyReplacementsWeb(1));
      dispatch(resetDialog());
      dispatch(resetStatus(successActions));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={10}>
        <DynamicTable
          table={Table}
          page={page}
          loadSuccess={loadSuccess}
          count={repl.count}
          handlePagination={handlePagination}
          totalCount={repl.totalCount}
        />
      </Grid>
      <Grid sx={ResponsiveClasses.hideOnLg} item xs={2}>
        <AdsContainer />
      </Grid>
    </Grid>
  );
};

export default MyReplacements;
