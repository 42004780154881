import React from 'react';

import { Box } from '@mui/system';
import { Paper } from '@mui/material';

import { Language, language } from '@pharmaplan/common';

import { useAppSelector } from '../../../hooks/useAppSelector';
import EnglishAd from '../../../assets/svg/englishAd.svg';
import FrenchAd from '../../../assets/svg/frenchAd.svg';

import classes from './style';

const { en, fr } = Language;

const AdsContainer = () => {
  const userLanguage = useAppSelector(language);

  const { adsContainer, adsContainerImg } = classes;

  const adConfig = {
    [en]: {
      src: EnglishAd,
    },
    [fr]: {
      src: FrenchAd,
    },
  };

  const { src } = adConfig[userLanguage as keyof typeof adConfig];

  return (
    <Box sx={adsContainer} component={Paper}>
      <Box component="img" style={adsContainerImg} src={src} alt="ads" />
    </Box>
  );
};

export default AdsContainer;
