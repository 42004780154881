import React, { useEffect } from 'react';
import {
  adminActions,
  adminGetPharmacyChain,
  adminGetPharmacyChainList,
  adminPharmacyChain,
  adminResetValue,
  adminUpdatePharmacyChain,
  getAllBannerLogos,
  resetStatus,
  successOrSelector,
  successSelector,
} from '@pharmaplan/common';
import { FormikValues } from 'formik';
import ChainUploadModal from '../../components/Admin/PharmacyChains/ChainUploadModal';
import strings from '../../localization';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { setDialog } from '../../reducers/dialogReducer';
import validator from '../../components/Admin/PharmacyChains/validator';

const {
  getPharmacyChain: getPharmacyChainAction,
  createPharmacyChain,
  updatePharmacyChain,
  deletePharmacyChains,
} = adminActions;

const successAction = getPharmacyChainAction;
const updateSuccess = updatePharmacyChain;
const addSuccess = createPharmacyChain;
const deleteSuccess = deletePharmacyChains;

const usePharmacyChains = () => {
  const dispatch = useAppDispatch();
  const pharmacyChain = useAppSelector(adminPharmacyChain);

  const { name, published, pharmacyChainId, imageName, logoImage, inUse } = pharmacyChain ?? {};
  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const addUpdateDeleteSuccess = useAppSelector((state) =>
    successOrSelector([updateSuccess, addSuccess, deleteSuccess], state));

  const handleSubmit = (values: FormikValues) => {
    let form = null;

    if (values.image?.size) {
      form = new FormData();
      form.append('image', values.image);
    }

    const isLogoChanged = !!values.image?.size || values.isLogoChanged;

    dispatch(
      adminUpdatePharmacyChain({
        form,
        isLogoChanged,
        name: values.name,
        published: values.published,
        pharmacyChainId: values.pharmacyChainId,
      }),
    );
  };

  const initialValues = {
    pharmacyChainId,
    name,
    logoImage,
    published,
    isLogoChanged: false,
    image: imageName
      ? {
        name: imageName,
      }
      : null,
  };

  const updateModal = () => {
    dispatch(
      setDialog({
        Component: (
          <ChainUploadModal
            inUse={inUse}
            submitLabel={strings.update}
            validator={validator()}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          />
        ),
        heading: {
          title: strings.update,
        },
        showCloseButton: true,
      }),
    );
  };

  const getPharmacyChain = (chainId: string) => {
    dispatch(adminGetPharmacyChain(chainId));
  };

  useEffect(() => {
    if (addUpdateDeleteSuccess) {
      dispatch(adminGetPharmacyChainList());
      dispatch(getAllBannerLogos());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateDeleteSuccess]);

  useEffect(() => {
    if (initialValues.pharmacyChainId && success) {
      updateModal();
      dispatch(resetStatus([successAction]));
      dispatch(adminResetValue(['pharmacyChain']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.pharmacyChainId, success]);

  return { getPharmacyChain };
};

export default usePharmacyChains;
