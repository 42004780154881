import React, { memo, useCallback, useState, Fragment } from 'react';

import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import { Box } from '@mui/material';
import moment from 'moment';

import {
  activeDate,
  getActiveWebView,
  setActiveDate,
} from '@pharmaplan/common';
import { ReactComponent as TodayIcon } from '@pharmaplan/common/assets/icons/todayIconWeb.svg';

import { CalendarNavigateKey } from '../../../../helpers/Constants';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import CustomButton from '../../../common/CustomButton';
import strings from '../../../../localization';

import classes from './style';

const { prev, next } = CalendarNavigateKey;

const navigateIcons = [
  {
    Icon: ChevronLeft,
    iconEnum: prev,
  },
  {
    Icon: ChevronRight,
    iconEnum: next,
  },
];

const NavigateButtons = () => {
  const [navigateBtn, setNavigateBtn] = useState<CalendarNavigateKey>();
  const dispatch = useAppDispatch();

  const activeDateSelector = useAppSelector(activeDate);
  const activeView = useAppSelector(getActiveWebView);

  const {
    toolbarToggleContainer,
    navButtonActive,
    navButtonIconCont,
    todayButton,
  } = classes ?? {};

  const navigateCalendar = useCallback(
    (iconEnum: CalendarNavigateKey) => {
      setNavigateBtn(iconEnum);

      const newDate = iconEnum === next
        ? moment(activeDateSelector).add(1, activeView)
        : moment(activeDateSelector).subtract(1, activeView);

      dispatch(setActiveDate(moment(newDate).toISOString()));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeView, activeDateSelector],
  );

  const setToday = () => {
    const todayDate = new Date();
    dispatch(setActiveDate(todayDate.toISOString()));
  };

  return (
    <Box display="flex" flexDirection="row" sx={toolbarToggleContainer}>
      {navigateIcons.map((nav, index) => {
        const { iconEnum, Icon } = nav ?? {};
        const isSameBtn = navigateBtn === iconEnum;
        const isFirstItem = index === 0;

        return (
          <Fragment key={iconEnum}>
            <Box
              sx={[navButtonIconCont, isSameBtn && navButtonActive]}
              display="flex"
              justifyContent="center"
              key={iconEnum}
              alignItems="center"
              onClick={() => {
                navigateCalendar(iconEnum);
              }}
              tabIndex={0}
            >
              <Icon />
            </Box>
            {isFirstItem && (
              <CustomButton
                disableElevation
                startIcon={<TodayIcon />}
                customClass={todayButton}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore - ignoring as we added custom TS for this.
                color="common"
                size="small"
                onClick={setToday}
                label={strings.today}
              />
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default memo(NavigateButtons);
