import { useEffect } from 'react';

import { DateTime } from 'luxon';
import { FormikValues, useFormik } from 'formik';

import {
  AllowanceTypes,
  AllowancesNames,
  TypeOfUser,
  calendarDate,
  convertToDecimals,
  createWorkshift,
  getHourlyRate,
  getPharmacyCalendar,
  getRecurrence,
  getTypeOfUser,
  keyToParse,
  pharmacyActions,
  pharmacyHourlyRate,
  reset,
  resetStatus,
  successSelector,
  valueCriteria,
} from '@pharmaplan/common';
import Validator from '../../components/SelfService/Workshifts/Validator';
import {
  resetDrawer,
  setButtons,
  setPagination,
} from '../../actions/drawerActions';
import { makeReqParams, setHour } from '../../helpers/workshift/functions';
import { DurationTypes } from '../../helpers/Constants';
import strings from '../../localization';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import useSelfService from '../useSelfService';
import useRecurrence from '../useRecurrence';

const {
  travelAllowanceType,
  accommodationAllowanceType,
  mealAllowanceType,
  paidHourlyRate,
  minFreeTravelDistance,
  travelAllowanceRatePerKm,
  fixedAccommodationRate,
  fixedMealRate,
  emergencyFees,
} = AllowancesNames;

const { notCovered } = AllowanceTypes;
const { pharmacist, pharmacy } = TypeOfUser;
const { notRepeat } = DurationTypes;

const useAddWorkshift = (date: string) => {
  const dispatch = useAppDispatch();
  const { allowedValuesParser, workshiftParser, isSelfService } = useSelfService();

  const recurrence = useAppSelector(getRecurrence);
  const cDate = useAppSelector(calendarDate);
  const hourlyRate = useAppSelector(pharmacyHourlyRate);
  const userType = useAppSelector(getTypeOfUser);
  const createSuccess = useAppSelector((state) =>
    successSelector([pharmacyActions.createWorkshift], state));

  const luxonDate = DateTime.fromISO(date);
  const isPharmacy = userType === pharmacy;
  const { hourlyRate: currentHourlyRate } = hourlyRate ?? {};

  const onSubmit = (values: FormikValues) => {
    const parsedValues = workshiftParser(values, keyToParse);

    const allowedValues = allowedValuesParser(
      values,
      parsedValues,
      valueCriteria,
    );

    const allowanceTypes = isSelfService
      ? {
        [travelAllowanceType]: values[travelAllowanceType],
        [accommodationAllowanceType]: values[accommodationAllowanceType],
        [mealAllowanceType]: values[mealAllowanceType],
      }
      : {};

    // NOTE: THE ORDER MATTERS
    dispatch(
      createWorkshift({
        ...allowanceTypes,
        ...parsedValues,
        ...allowedValues,
        ...makeReqParams(values, recurrence),
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      selectedDate: luxonDate,
      allDay: false,
      startTime: null,
      endTime: null,
      notes: '',
      recurrence: notRepeat,
      isSelfService,

      [travelAllowanceType]: notCovered,
      [accommodationAllowanceType]: notCovered,
      [mealAllowanceType]: notCovered,

      [paidHourlyRate]: '',
      [minFreeTravelDistance]: '',
      [travelAllowanceRatePerKm]: '',
      [fixedAccommodationRate]: '',
      [fixedMealRate]: '',
      [emergencyFees]: '',
      replacement: pharmacist,
    },
    onSubmit,
    validationSchema: Validator(),
  });

  const { setFieldValue, handleSubmit } = formik ?? {};
  useRecurrence(formik);

  const handleReset = () => {
    dispatch(reset());
    dispatch(resetDrawer());
  };

  const setUserWorkTimings = () => {
    setFieldValue('startTime', setHour(luxonDate, 8));
    setFieldValue('endTime', setHour(luxonDate, 22));
  };

  useEffect(() => {
    dispatch(setPagination({ show: false }));
    dispatch(
      setButtons({
        show: true,
        primaryButton: {
          label: strings.createWorkshift,
          onClick: handleSubmit,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createSuccess) {
      dispatch(getPharmacyCalendar(cDate));
      handleReset();
      dispatch(resetStatus());
    }
    isPharmacy && isSelfService && dispatch(getHourlyRate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess]);

  useEffect(() => {
    setFieldValue(paidHourlyRate, convertToDecimals(currentHourlyRate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHourlyRate]);

  return { setUserWorkTimings, isSelfService, formik };
};

export default useAddWorkshift;
