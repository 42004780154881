import React, { useEffect } from 'react';

import { FormikValues } from 'formik';

import {
  adminActions,
  adminCreateSoftware,
  adminGetSoftwareList,
  adminSoftwareList,
  IAdminCreateSoftwareApiParams,
  PermissionsOfAdmin,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';
import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';
import CreateNew from '../../common/CreateNew';
import { setDialog } from '../../../reducers/dialogReducer';
import InputModal from '../../Modals/InputModal';
import Validator from './validator';
import useSofwareList from '../../../hooks/SoftwareList/useSoftwareList';
import usePublishConfigurations from '../../../hooks/Admin/usePublishConfigurations';

import {
  addNewSoftwareForm,
  softwareListHeaders,
  softwareListMap,
} from './helper';
import useConfigurationsSuccess from '../../../hooks/Admin/useConfigurationsSuccess';
import useDeleteConfirmation from '../../../hooks/Admin/useDeleteConfirmation';
import { DeleteConfirmationType } from '../../../helpers/Constants';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';
import { canCreateNew } from '../PharmacistCapacities/helper';

const addSoftwareInitialValues = {
  name: '',
  description: '',
  published: false,
};

const { createSoftware, updateSoftware } = adminActions;

const SoftwareList = () => {
  const dispatch = useAppDispatch();
  const softwareList = useAppSelector(adminSoftwareList);
  const { getSoftware } = useSofwareList();
  const { showDeleteConfirmation } = useDeleteConfirmation(
    DeleteConfirmationType.softwares,
  );

  const { can } = useAdminPermissions();
  const canChangeParameters = can(PermissionsOfAdmin.ChangeParameters);
  const canDelete = can(PermissionsOfAdmin.Delete);

  useConfigurationsSuccess({
    addAction: createSoftware,
    editAction: updateSoftware,
  });

  const {
    setPublishedRecords,
    handlePublish,
    mapPublishedRecords,
    publishedRecords,
  } = usePublishConfigurations();

  useEffect(() => {
    const softwareIdPublishMap = mapPublishedRecords(
      softwareList,
      'softwareId',
      'published',
    );
    setPublishedRecords(softwareIdPublishMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [softwareList]);

  useEffect(() => {
    dispatch(adminGetSoftwareList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addSoftware = (values: FormikValues) => {
    dispatch(adminCreateSoftware(values as IAdminCreateSoftwareApiParams));
  };

  const createModal = () => {
    dispatch(
      setDialog({
        Component: (
          <InputModal
            onSubmit={addSoftware}
            initialValues={addSoftwareInitialValues}
            submitLabel={strings.create}
            config={addNewSoftwareForm}
            validator={Validator()}
          />
        ),
        heading: {
          title: strings.addNew,
        },
        showCloseButton: true,
      }),
    );
  };

  const table: IDynamicTableObject = {
    title: strings.softwareList,
    headers: softwareListHeaders(),
    rows: softwareListMap(
      softwareList,
      handlePublish,
      publishedRecords,
      getSoftware,
      showDeleteConfirmation,
      canChangeParameters,
      canDelete,
    ),
    headerBar: canCreateNew(canChangeParameters, {
      Component: <CreateNew label={strings.createNew} onClick={createModal} />,
      key: '1',
    }),
  };

  return <DynamicTable hidePagination table={table} loadSuccess />;
};

export default SoftwareList;
