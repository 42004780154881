import { SxProps } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  root: {
    display: 'flex',
    borderBottom: '1px solid #E1E4EF',
    marginBottom: '24px',
  },

  navButton: {
    height: '100%',
    borderRadius: 0,
    padding: '11px 8px',
    marginRight: '40px',
    fontSize: 14,
    fontWeight: '500',
  },

  activeButton: {
    borderBottom: '2px solid green',
    fontWeight: '600',
  },

  inActive: {
    color: ThemeConstants.common.grey850,
    opacity: 0.8,
  },

  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '16px',
    paddingBottom: '24px',
    justifyContent: 'space-between',
  },

  headerFont: {
    fontSize: 18,
    fontWeight: 600,
  },
} satisfies Record<string, SxProps>;

export default classes;
