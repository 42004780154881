import { createReducer } from "@reduxjs/toolkit";
import { logout, reset, setRecurrence } from "../actions";
import { MonthlyYearlyRecurrence, Ordinals, recurrenceType } from "../helpers/Constants";

export interface IRecurrenceState {
  repeatEvery: recurrenceType;
  endDate: string;
  weeklyRecurrence: string;
  weeklyRecurrenceFullDay: string[];
  // selectedDate: Date;
  weekDayRecurrence:string;
  recurrencDayMonth: `${MonthlyYearlyRecurrence}`;
  onDay: string;
  onThe: string|number;
  onTheMonth: string;
  onTheDay: string;
}
export interface IInitialState {
  recurrence: IRecurrenceState;
}

export const recurrenceInitialState: IInitialState = {
  recurrence: {
    // selectedDate: new Date(),
    endDate: new Date().toISOString(),
    repeatEvery: recurrenceType.Never,
    weeklyRecurrence: "",
    weekDayRecurrence:"",
    weeklyRecurrenceFullDay: [],
    recurrencDayMonth: MonthlyYearlyRecurrence.day,
    onDay: "1",
    onThe: Ordinals.first,
    onTheMonth: "1",
    onTheDay: "0",
  },
};

const recurrenceReducer = createReducer(recurrenceInitialState, (builder) => {
  builder
    .addCase(setRecurrence, (state, action) => {
      state.recurrence = action.payload;
    })
    .addCase(reset, () => {
      return recurrenceInitialState;
    }) //reset all on logout
    .addCase(logout.fulfilled, () => recurrenceInitialState);
});

export default recurrenceReducer;
