import {
  IAdminContactPreferences,
  adminUpdateContactPreference,
} from '@pharmaplan/common';
import {
  Constants,
  DynamicTableCell,
  TextFieldVariants,
} from '../../../helpers/Constants';
import strings from '../../../localization';
import { IInputModalConfig } from '../../Modals/InputModal/InputModal';
import genericClasses from '../../../theme/GenericClasses';
import editIcon from '../../../assets/svg/updateNoteIcon.svg';
import deleteIcon from '../../../assets/svg/delete.svg';
import { IHandlePublishSignature } from '../../../hooks/Admin/usePublishConfigurations';

import styles from './styles';

export const contactPreferenceHeaders = () =>
  [
    {
      key: '0',
      label: strings.name,
    },
    {
      key: '1',
      label: strings.published,
    },
    {
      key: '2',
      label: strings.action,
    },
  ];

export const contactPreferenceMap = (
  contactPreferenceList: IAdminContactPreferences[],
  publishedRecords: Record<string, boolean>,
  handlePublish: IHandlePublishSignature,
  showEdit: (prefId: string) => void,
  showDeleteConfirmation: (prefId: string) => void,
  canChangeParameters: boolean,
  canDelete: boolean,
) =>
  contactPreferenceList?.map((item) => {
    const { name, contactPreferenceId, inUse } = item ?? {};

    const value = [
      canChangeParameters && {
        key: '1',
        icon: editIcon,
        show: false,
        tooltip: strings.edit,
        onClick: () =>
          showEdit(contactPreferenceId),
      },
      canDelete && {
        key: '2',
        icon: deleteIcon,
        show: false,
        tooltip: strings.delete,
        onClick: () =>
          showDeleteConfirmation(contactPreferenceId),
      },
    ];

    return {
      key: contactPreferenceId,
      data: [
        {
          key: `0-${contactPreferenceId}`,
          value: name,
          type: DynamicTableCell.text,
        },
        {
          key: `1-${contactPreferenceId}`,
          value: {
            checked: !!publishedRecords[contactPreferenceId],
            tooltipText: strings.inUse,
            disabled: inUse || !canChangeParameters,
            handlePress: handlePublish(
              {
                contactPreferenceId,
                name,
                published: !publishedRecords[contactPreferenceId],
              },
              'contactPreferenceId',
              adminUpdateContactPreference,
            ),
          },
          isCenterAligned: true,
          type: DynamicTableCell.checkbox,
        },

        !inUse
          ? {
            key: `2-${contactPreferenceId}`,
            value,
            type: DynamicTableCell.bar,
          }
          : {
            key: `3-${contactPreferenceId}`,
            value: {
              label: strings.inUse,
              style: styles.chipStyle,
            },
            type: DynamicTableCell.chip,
          },
      ],
    };
  });

export const addNewContactPreferencesForm: IInputModalConfig[] = [
  {
    key: '0',
    name: 'name',
    type: Constants.formInputType.text,
    gridLabel: strings.name,
    customTextFieldStyle: genericClasses.customFilledInput,
    variant: TextFieldVariants.filled,
    fieldXs: 8,
    labelXs: 4,
  },
  {
    key: '1',
    name: 'published',
    type: Constants.formInputType.checkbox,
    customClass: genericClasses.checkboxContainer,
    customCheckboxStyle: genericClasses.checkboxStyle,
    gridLabelClass: styles.gridLabel,
    gridLabel: strings.publish,
    label: ' ',
    fieldXs: 8,
    labelXs: 4,
  },
];
