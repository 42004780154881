import {
  adminActions,
  pharmacistActions,
  pharmacyActions,
  pharmacyAdminActions,
  userActions,
} from '../actions';
// User Array
const user = Object.values(userActions);
const pharmacist = Object.values(pharmacistActions);
const pharmacy = Object.values(pharmacyActions);
const admin = Object.values(adminActions);
const pharmacyAdmin = Object.values(pharmacyAdminActions);

const actionTrackerList = [
  ...user,
  ...pharmacist,
  ...pharmacy,
  ...admin,
  ...pharmacyAdmin,
];

export default actionTrackerList;
