import React, { useEffect } from 'react';

import { FormikValues } from 'formik';

import {
  adminActions,
  adminContactPreferenceList,
  adminCreateContactPreference,
  IAdminCreateContactPreferenceApiParams,
  PermissionsOfAdmin,
  successSelector,
} from '@pharmaplan/common';

import { useAppSelector } from '../../../hooks/useAppSelector';
import useContactPreferences from '../../../hooks/Admin/ContactPreferences/useContactPreferences';
import strings from '../../../localization';

import CreateNew from '../../common/CreateNew';
import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';

import { contactPreferenceHeaders, contactPreferenceMap } from './helper';
import styles from './styles';
import { DeleteConfirmationType } from '../../../helpers/Constants';
import useDeleteConfirmation from '../../../hooks/Admin/useDeleteConfirmation';
import usePublishConfigurations from '../../../hooks/Admin/usePublishConfigurations';
import useConfigurationsSuccess from '../../../hooks/Admin/useConfigurationsSuccess';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';
import { canCreateNew } from '../PharmacistCapacities/helper';

const initialValues = {
  name: '',
  published: false,
};

const { createContactPreference: createAction, updateContactPreference } = adminActions;

const ContactPreferences = () => {
  const dispatch = useAppDispatch();
  const { openForm, handleUpdate } = useContactPreferences();
  const { showDeleteConfirmation } = useDeleteConfirmation(
    DeleteConfirmationType.contactPreferences,
  );

  const {
    setPublishedRecords,
    handlePublish,
    mapPublishedRecords,
    publishedRecords,
  } = usePublishConfigurations();

  const { can } = useAdminPermissions();

  const canChangeParameters = can(PermissionsOfAdmin.ChangeParameters);
  const canDelete = can(PermissionsOfAdmin.Delete);

  const contactPreferenceList = useAppSelector(adminContactPreferenceList);

  const loadSuccess = useAppSelector((state) =>
    successSelector([adminActions.getContactPreferencesList], state));

  const createContactPreference = (values: FormikValues) => {
    dispatch(
      adminCreateContactPreference(
        values as IAdminCreateContactPreferenceApiParams,
      ),
    );
  };

  const heading = `${strings.addNew} ${strings.contactPreference}`;

  const showCreateNew = () => {
    openForm(initialValues, createContactPreference, strings.create, heading);
  };

  const table: IDynamicTableObject = {
    title: strings.contactPreferences,
    rows: contactPreferenceMap(
      contactPreferenceList,
      publishedRecords,
      handlePublish,
      handleUpdate,
      showDeleteConfirmation,
      canChangeParameters,
      canDelete,
    ),
    headers: contactPreferenceHeaders(),
    headerBar: canCreateNew(canChangeParameters, {
      Component: (
        <CreateNew label={strings.createNew} onClick={showCreateNew} />
      ),
      key: '1',
    }),
  };

  useConfigurationsSuccess({
    addAction: createAction,
    editAction: updateContactPreference,
  });

  useEffect(() => {
    const contactPreferencePublishMap = mapPublishedRecords(
      contactPreferenceList,
      'contactPreferenceId',
      'published',
    );
    setPublishedRecords(contactPreferencePublishMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactPreferenceList]);

  return (
    <DynamicTable
      customContainerStyle={styles.container}
      loadSuccess={loadSuccess}
      hidePagination
      table={table}
    />
  );
};

export default ContactPreferences;
