import React, { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBack';

import {
  adminPharmacistPictures,
  adminPharmacistRatings,
  convertToDecimals,
  getAdminPharmacistRating,
  getAdminRatingItems,
  getPharmacistDetails,
  getRatingDetails,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getDayMonthDateYear, getHourMinute } from '../../Reports/helpers';
import strings from '../../../localization';
import { Constants } from '../../../helpers/Constants';

import EmptyIncompatibleMatches from '../IncompatibleMatches/EmptyIncompatibleMatches';

import AllRatings from './AllRatings';
import PharmacistRating from './PharmacistRating';
import styles from './style';

const DetailedRatings = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const details = useAppSelector(adminPharmacistRatings);
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const noData = details.length === 0;
  const pharmacistId = state ?? {};

  const backToPharmacistList = () => {
    navigate(Constants.paths.admin.pharmacistList);
  };

  useEffect(() => {
    dispatch(getAdminRatingItems());
    dispatch(getAdminPharmacistRating(pharmacistId));
    dispatch(adminPharmacistPictures([pharmacistId]));
    dispatch(getPharmacistDetails(pharmacistId));
    dispatch(getRatingDetails(pharmacistId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Box
          component={IconButton}
          onClick={backToPharmacistList}
          sx={styles.backButtonContainer}
        >
          <BackIcon sx={styles.backIcon} />
          <Typography sx={styles.backButtonText}>
            {`${strings.backTo} ${strings.pharmacistList}`}
          </Typography>
        </Box>
        <Typography sx={styles.title}>{strings.pharmacistRating}</Typography>
        <PharmacistRating pharmacistId={pharmacistId} />
      </Box>

      <Box sx={styles.container}>
        <Typography sx={styles.title}>{strings.allRatings}</Typography>
        {noData ? (
          <EmptyIncompatibleMatches message={strings.noDataAvailable} />
        ) : (
          <Box flexGrow={1}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {details.map((item) => {
                const {
                  pharmacistRatingId,
                  pharmacyName,
                  note,
                  ratingPercentage,
                  createDate,
                  levels,
                  pharmacyCode,
                  logoId,
                } = item ?? {};

                const percentage = parseFloat(ratingPercentage ?? '0');
                const rating = (percentage / 100) * 5;
                const formattedDate = getDayMonthDateYear(createDate);
                const time = getHourMinute(createDate, timeFormat);

                const date = `${strings.createdOn} ${formattedDate} | ${time}`;

                const info = {
                  name: pharmacyName,
                  pharmacyCode,
                  date,
                  rating: convertToDecimals(rating),
                  note,
                  logoId,
                  levels,
                  percentage,
                };

                return (
                  <Grid item xs={2} sm={4} md={4} key={pharmacistRatingId}>
                    <AllRatings info={info} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DetailedRatings;
