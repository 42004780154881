import { IAdminIncompatibleMatchData, TypeOfUser } from '@pharmaplan/common';
import { DynamicTableCell, OtherScreens } from '../../../helpers/Constants';
import { IUserProfileLinkSignature } from '../../../hooks/Admin/useUserProfileLink';
import strings from '../../../localization';

const { text, customLink } = DynamicTableCell;
const { pharmacist } = TypeOfUser;

export const incompatibleMatchHeaders = () =>
  [
    { key: '1', label: strings.pharmacistName },
    { key: '2', label: strings.email },
    { key: '3', label: strings.mobile },
    { key: '4', label: strings.phone },
    { key: '5', label: strings.licenseNumber },
    { key: '6', label: strings.city },
    { key: '7', label: strings.address },
  ];

export const incompatibleMatchRows = (
  data: Array<IAdminIncompatibleMatchData>,
  goToUserProfile: IUserProfileLinkSignature,
) =>
  data?.map((item) => {
    const {
      email,
      name,
      mobile,
      city,
      address,
      pharmacistId,
      phone,
      licenseNumber,
    } = item ?? {};
    return {
      key: pharmacistId,
      data: [
        {
          key: '1',
          value: {
            label: name,
            handlePress: goToUserProfile({
              personnelId: pharmacistId,
              userType: pharmacist,
              fromScreen: OtherScreens.incompatibleMatches,
            }),
          },
          type: customLink,
        },
        {
          key: '2',
          maxWidth: 200,
          value: email,
          type: text,
        },
        {
          key: '3',
          value: mobile,
          type: text,
        },
        {
          key: '4',
          value: phone,
          type: text,
        },
        {
          key: '5',
          value: licenseNumber,
          type: text,
          isCenterAligned: true,
        },
        {
          key: '6',
          value: city,
          type: text,
        },
        {
          key: '7',
          value: address,
          type: text,
        },
      ],
    };
  });
