import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  customSliderContainer: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  label: {
    color: ThemeConstants.common.grey850,
  },
};

export default classes;
