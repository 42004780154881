import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  ({
    downChevronIcon: {
      cursor: 'pointer',
      color: theme.palette.common.grey350,
    },
    yearLabel: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    yearCalendarContainer: {
      padding: '20px',
      borderRadius: '4px',
      zIndex: 100,
      position: 'absolute',
      border: 'none',
      boxShadow: '0px 2px 24px 0px #00000014',
      '& button': {
        '&:hover': {
          backgroundColor: `${theme.palette.primary.light} !important`,
          color: `${theme.palette.primary.main} !important`,
        },
      },
    },
    yearCalendarHidden: {
      display: 'none',
    },
    monthViewReorder: {
      flex: '0 0 25% !important',
      fontWeight: 500,
      fontSize: '16px',
      margin: '0.5em 0px !important',
      padding: '1.5em 6px !important',
      borderRadius: '10px',
      '& --now': {
        backgroundColor: 'red',
      },
    },
  }));

export default useStyles;
