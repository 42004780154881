import { SxProps, Theme } from '@mui/system';

const styles = {
  leftList: {
    minHeight: 318,
    maxHeight: 318,
  },
  container: {
    padding: '24px 32px',
    margin: '6px',
    width: '99%',
    backgroundColor: 'secondary.main',
  },
  title: {
    fontSize: 21,
    fontWeight: '600',
    color: 'common.grey850',
    marginBottom: '32px',
  },
  listContainer: {
    marginTop: '40px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: '60px',
  },
  button: {
    minWidth: 180,
  },
  searchContainer: {
    mb: '3px',
  },
  borderRadiusRemove: {
    '& .MuiInputBase-root': {
      borderRadius: 0,
    },
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
