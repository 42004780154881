import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const styles = {
  text: {
    fontSize: 14,
    marginLeft: '8px',
    color: ThemeConstants.common.gray,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
