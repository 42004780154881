import {
  convertToDecimals,
  currency,
  IPharmaciesInPharmacyAdmin,
  TypeOfUser,
} from '@pharmaplan/common';
import { DynamicTableCell } from '../../../../helpers/Constants';
import { IUserProfileLinkSignature } from '../../../../hooks/Admin/useUserProfileLink';
import appointIcon from '../../../../assets/svg/appointIcon.svg';
import strings from '../../../../localization';
import { IAppointAction } from './AppointExistingPharmacy';

const { text, customLink, icon, bar } = DynamicTableCell;

interface IAppointPharmaciesRow {
  goToUserProfile: IUserProfileLinkSignature;
  appointAction: (params: IAppointAction) => () => void;
  data: Array<IPharmaciesInPharmacyAdmin>;
  getSoftware: (id: string) => string;
  getImage: (id: string) => string;
}

export const appointPharmaciesHeaders = () =>
  [
    { key: '0', label: strings.banner },
    { key: '1', label: strings.bannerNumber },
    { key: '2', label: strings.pharmacyName },
    { key: '3', label: strings.contactName },
    { key: '4', label: strings.email },
    { key: '5', label: strings.mobile },
    { key: '6', label: strings.city },
    { key: '7', label: strings.software },
    { key: '8', label: strings.pharmacyHourlyRate, isCenterAligned: true },
    { key: '9', label: strings.action },
  ];

export const appointPharmaciesRows = ({
  data,
  getImage,
  getSoftware,
  appointAction,
  goToUserProfile,
}: IAppointPharmaciesRow) =>
  data?.map((item) => {
    const {
      pharmacyId,
      name,
      contactName,
      email,
      mobile,
      softwareId,
      pharmacyHourlyRate,
      city,
      logoId,
      bannerNumber,
    } = item ?? {};
    return {
      key: pharmacyId,
      data: [
        {
          key: '0',
          value: getImage(logoId),
          type: icon,
        },
        {
          key: '1',
          value: bannerNumber,
          type: text,
        },
        {
          key: '2',
          maxWidth: 200,
          value: {
            label: name,
            handlePress: goToUserProfile({
              personnelId: pharmacyId,
              userType: TypeOfUser.pharmacy,
            }),
          },
          type: customLink,
        },
        {
          key: '3',
          value: contactName,
          type: text,
        },
        {
          key: '4',
          value: email,
          maxWidth: 200,
          type: text,
        },
        {
          key: '5',
          value: mobile,
          type: text,
        },
        {
          key: '6',
          value: city,
          type: text,
        },
        {
          key: '7',
          value: getSoftware(softwareId),
          type: text,
        },
        {
          key: '8',
          value: `${currency}${convertToDecimals(pharmacyHourlyRate)}`,
          type: text,
          isCenterAligned: true,
        },
        {
          key: '9',
          value: [
            {
              key: '1',
              tooltip: strings.appoint,
              icon: appointIcon,
              onClick: appointAction({ id: pharmacyId, email, name }),
            },
          ],
          type: bar,
        },
      ],
    };
  });
