import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const styles = {
  toolbar: {
    display: 'flex',
    flex: 1,
    marginLeft: '10px',
    maxHeight: '50px !important',
    minHeight: '35px !important',
  },
  navButton: {
    height: '100%',
    borderRadius: 0,
    padding: '11px 8px',
    marginRight: '40px',
    fontSize: 14,
    fontWeight: '500',
  },
  activeButton: {
    borderBottom: '2px solid green',
    fontWeight: '600',
  },
  inActive: {
    color: ThemeConstants.common.grey850,
    opacity: 0.8,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
