import { IAllLanguages } from '@pharmaplan/common';
import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import strings from '../../../localization';
import { Constants, telephoneInputMask } from '../../../helpers/Constants';

export const adminProfileGeneralSection = (languages: IAllLanguages[]) =>
  ({
    general: {
      title: strings.general.toUpperCase(),
      type: 'single',
      form: () =>
        [
          {
            name: 'userName',
            label: strings.userName,
            requireLabel: true,
            type: Constants.formInputType.text,
            xs: 6,
          },
          {
            name: 'email',
            label: strings.email,
            requireLabel: true,
            disabled: true,
            type: Constants.formInputType.text,
            xs: 6,
          },
          {
            label: strings.preferredLanguage,
            type: Constants.formInputType.dropdown,
            xs: 6,
            requireLabel: true,
            name: 'languageId',
            id: 'languageId',
            menuItems: dropdownMapper(languages, 'languageId', 'name'),
          },
          {
            name: 'phone',
            label: strings.phone,
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            xs: 6,
          },
        ],
    },
  });
