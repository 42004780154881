import {
  IPharmacyAdminsListingAPIResponse,
  PermissionsOfAdmin,
  TypeOfUser,
} from '@pharmaplan/common';

import { IUserProfileLinkSignature } from '../../../hooks/Admin/useUserProfileLink';
import { DynamicTableCell } from '../../../helpers/Constants';
import editIcon from '../../../assets/svg/updateNoteIcon.svg';
import deleteIcon from '../../../assets/svg/delete.svg';
import strings from '../../../localization';

const { text, customLinkList, bar } = DynamicTableCell;
const actionKey = 'action';

interface IPharmacyAdminRows {
  data: Array<IPharmacyAdminsListingAPIResponse>;
  editAction: (pharmacyCompanyId: string) => () => void;
  deleteAction: (pharmacyCompanyId: string) => () => void;
  goToUserProfile: IUserProfileLinkSignature;
  can: (perm: PermissionsOfAdmin) => boolean;
}

export const pharmacyAdminHeaders = (
  can: (perms: PermissionsOfAdmin) => boolean,
) => {
  const cannotViewPharmacyAdmin = !can(PermissionsOfAdmin.PharmacyAdmin);

  let arr = [
    { key: '0', label: strings.name },
    { key: '1', label: strings.email },
    { key: '2', label: strings.mobile },
    { key: '3', label: strings.pharmacies },
    { key: actionKey, label: strings.action },
  ];

  if (cannotViewPharmacyAdmin) {
    arr = arr.filter((item) =>
      item.key !== actionKey);
  }

  return arr;
};

export const pharmacyAdminRows = ({
  data,
  editAction,
  deleteAction,
  goToUserProfile,
  can,
}: IPharmacyAdminRows) =>
  data?.map((item) => {
    const { name, email, mobile, pharmacies, pharmacyCompanyId } = item;
    const canViewPharmacyAdmin = can(PermissionsOfAdmin.PharmacyAdmin);
    let value: {
      key: string;
      icon: string;
      show: boolean;
      tooltip: string;
      onClick: () => void;
    }[] = [];

    if (canViewPharmacyAdmin) {
      value = [
        {
          key: '1',
          icon: editIcon,
          show: false,
          tooltip: strings.edit,
          onClick: editAction(pharmacyCompanyId),
        },
        {
          key: '2',
          icon: deleteIcon,
          show: false,
          tooltip: strings.delete,
          onClick: deleteAction(pharmacyCompanyId),
        },
      ];
    }

    return {
      key: pharmacyCompanyId,
      data: [
        {
          key: '1',
          type: text,
          value: name,
        },
        {
          key: '2',
          type: text,
          value: email,
          maxWidth: 200,
        },
        {
          key: '3',
          type: text,
          value: mobile,
        },
        {
          key: '4',
          type: customLinkList,
          value: pharmacies?.map((inItem) => {
            const { id, userName } = inItem ?? {};
            return {
              key: id,
              label: userName,
              onClick: goToUserProfile({
                personnelId: id,
                userType: TypeOfUser.pharmacy,
              }),
            };
          }),
          maxWidth: 200,
        },
        {
          key: '5',
          type: bar,
          value,
        },
      ],
    };
  });
