import React, { ChangeEvent, ReactNode, useState } from 'react';
import { Box, InputLabel, TextField, Theme, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { SxProps, SystemStyleObject } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Constants, InputTypes } from '../../../helpers/Constants';
import showPassword from '../../../assets/svg/showPassword.svg';
import hidePassword from '../../../assets/svg/hidePassword.svg';

import CustomStrengthBar from '../CustomStrengthBar';
import classes from './style';
import useTextField from '../../../hooks/useTextField';

interface ICustomTextField {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  name: string;
  id: string;
  multiline?: boolean;
  label?: string;
  maxLength?: number | null;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: string;
  requireLabel?: boolean;
  customType?: `${InputTypes}`;
  horizontal?: boolean;
  customClass?: Record<string, unknown>;
  placeholder?: string | string[];
  variant?: 'outlined' | 'standard' | 'filled';
  inputRef?: any;
  showStrentghBar?: boolean;
  suffix?: string;
  customLabelStyle?: SxProps;
  customTextFieldStyle?: SxProps<Theme>;
  hint?: string | null;
  capitalize?: boolean;
  isCustomError?: boolean;
  rightIcon?: string;
  EndAdornment?: React.ReactNode;
  customHelperText?: string;
  startAdornment?: any;
  usePreFilledColors?: boolean;
  errorContainer?: boolean;
}

const defaultProps = {
  disabled: false,
  label: Constants.null,
  customType: Constants.null,
  maxLength: Constants.null,
  fullWidth: true,
  type: InputTypes.text,
  requireLabel: false,
  customClass: {},
  multiline: false,
  horizontal: false,
  variant: 'outlined',
  placeholder: Constants.null,
  inputRef: null,
  showStrentghBar: false,
  suffix: '',
  hint: Constants.null,
  capitalize: false,
  isCustomError: false,
  rightIcon: null,
  customHelperText: null,
};

const CustomTextField = ({
  fullWidth,
  formik,
  disabled,
  maxLength,
  id,
  name,
  label,
  type,
  customType,
  placeholder,
  requireLabel,
  multiline,
  horizontal,
  customClass,
  variant,
  inputRef,
  showStrentghBar,
  suffix,
  customLabelStyle,
  hint,
  customTextFieldStyle,
  capitalize,
  isCustomError,
  rightIcon,
  EndAdornment,
  customHelperText,
  usePreFilledColors,
  startAdornment,
  errorContainer = true,
}: ICustomTextField) => {
  const [showHidePass, setShowHidePass] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const { errors, touched, values } = formik ?? {};
  const { style } = useTextField({
    value: formik.values[name],
    usePreFilledColors,
  });

  const formikErrorValidate = errors[name] && touched[name] && errors[name];
  const isFormikErrorValidate = !!formikErrorValidate;

  const helperText = isFormikErrorValidate
    ? (formikErrorValidate as ReactNode)
    : ' ';

  const isPassword = type === InputTypes.password;
  const isSuffix = !!suffix;
  const isRightIcon = !!rightIcon;
  const isEndAdornment = !!EndAdornment;
  const isCustomHelperText = !!customHelperText;

  const shouldShowHint = showHint && hint && !touched[name];
  const passwordIcon = showHidePass ? hidePassword : showPassword;

  const handleOnFocus = () => {
    setShowHint(true);
  };

  const handleOnBlur = () => {
    setShowHint(false);
  };

  const handleType = () => {
    if (isPassword) {
      return showHidePass ? InputTypes.text : InputTypes.password;
    }

    return type;
  };

  const handleShowHidePass = () => {
    setShowHidePass(!showHidePass);
  };

  const handleEndAdornment = () => {
    switch (true) {
      case isPassword:
        return (
          <Box
            component="img"
            draggable={false}
            src={passwordIcon}
            sx={classes.cursor}
            onClick={handleShowHidePass}
          />
        );
      case isSuffix:
        return <Typography sx={classes.suffix}>{suffix}</Typography>;
      case isRightIcon:
        return (
          <Box
            component="img"
            draggable={false}
            src={rightIcon}
            sx={classes.cursor}
          />
        );
      case isEndAdornment:
        return EndAdornment;
      default:
        return null;
    }
  };

  const handleHelperText = () => {
    if (!errorContainer) {
      return '';
    }

    if (!isCustomError && isFormikErrorValidate) {
      return helperText;
    }

    if (isCustomHelperText) {
      return customHelperText;
    }

    return ' ';
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isPostalCode = customType === InputTypes.postalCode;

    if (isPostalCode) {
      e.target.value = e.target.value.replace(/\s/g, '').toUpperCase();
      formik?.handleChange(e);
    }

    formik?.handleChange(e);
  };

  return (
    <Box
      component="div"
      sx={[
        horizontal ? classes.horizontalTextField : {},
        customClass as SystemStyleObject,
      ]}
    >
      {label && (
        <InputLabel sx={customLabelStyle}>
          {label}
          {requireLabel && (
            <Box component="span" sx={classes.customTextFieldRequireLabel}>
              *
            </Box>
          )}
        </InputLabel>
      )}

      {shouldShowHint && (
        <Box component="div" sx={classes.hintContainer}>
          <InfoOutlinedIcon fontSize="inherit" sx={classes.infoIcon} />
          <Typography sx={classes.hintText} variant="caption">
            {hint}
          </Typography>
        </Box>
      )}

      <TextField
        type={handleType()}
        placeholder={(placeholder as string) || label}
        fullWidth={fullWidth}
        key={id}
        inputRef={inputRef}
        multiline={multiline}
        variant={variant}
        rows={3}
        sx={
          [
            multiline ? classes.filledInput : {},
            { input: style },
            customTextFieldStyle,
          ] as SxProps
        }
        name={name}
        id={id}
        disabled={disabled}
        inputProps={{
          maxLength,
          style: capitalize ? classes.capitalize : {},
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          endAdornment: handleEndAdornment(),
          startAdornment,
        }}
        error={isFormikErrorValidate}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        value={values[name]}
        helperText={handleHelperText()}
        onChange={handleChange}
        FormHelperTextProps={{
          sx: isCustomHelperText ? classes.customHelperText : {},
        }}
      />

      {showStrentghBar && <CustomStrengthBar password={values[name]} />}

      {isCustomError && (
        <Box sx={classes.errorContainer}>
          {isFormikErrorValidate && (
            <Typography sx={classes.customErrorMessage}>
              {helperText}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
export default CustomTextField;

CustomTextField.defaultProps = defaultProps;
