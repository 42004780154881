import React, { FC } from 'react';

import { Divider, Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';

import {
  AdminTypes,
  allAdminPermissions,
  PermissionsOfAdmin,
} from '@pharmaplan/common';
import { Box } from '@mui/system';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import strings from '../../../../../localization';
import CustomCheckbox from '../../../../common/CustomCheckbox';

import styles from '../styles';
import genericClasses from '../../../../../theme/GenericClasses';

const {
  ExpressBooking,
  Posting,
  ChangeParameters,
  Delete,
  Activation,
  SetupIncompatibleMatch,
  LogsView,
  BroadcastNotification,
  PermanentDelete,
  GlobalSetting,
  AdministratorList,
  PharmacyAdmin,
} = PermissionsOfAdmin;

interface IAdminPermissions {
  formik: FormikProps<any>;
}

const name = 'permissions';

const AdminPermissions: FC<IAdminPermissions> = ({ formik }) => {
  const allPermissions = useAppSelector(allAdminPermissions);
  const { userType } = formik.values;
  const isSuperAdminSelected = userType === AdminTypes.superadmin;

  const permissionTypeMap = allPermissions.permissions?.reduce(
    (acc: Record<PermissionsOfAdmin, string>, curr) => {
      const { permissionId, type } = curr;
      acc[type] = permissionId;
      return acc;
    },
    {} as Record<PermissionsOfAdmin, string>,
  );

  const permissionLanguageMap = {
    [Posting]: strings.posting,
    [Activation]: strings.activation,
    [ExpressBooking]: strings.expressBooking,
    [SetupIncompatibleMatch]: strings.setupIncompatibleMatch,
    [Delete]: strings.delete,
    [LogsView]: strings.logsView,
    [ChangeParameters]: strings.changeParameters,
    [BroadcastNotification]: strings.broadcastNotifications,
    [AdministratorList]: strings.administratorList,
    [GlobalSetting]: strings.globalSettings,
    [PermanentDelete]: strings.permanentDelete,
    [PharmacyAdmin]: strings.pharmacyAdministrators,
  };

  const handleChange = (key: string) =>
    () => {
      const { permissions } = formik.values;
      if (permissions.includes(key)) {
        formik.setFieldValue(
          'permissions',
          permissions.filter((item: string) =>
            item !== key),
        );
      } else {
        formik.setFieldValue('permissions', [...permissions, key]);
      }
    };

  const isFormikError = formik.errors[name] && formik.touched[name];

  return (
    <>
      <Grid container xs={7}>
        <Grid item sx={styles.permissionsTitleContainer} xs={12}>
          <Typography sx={styles.selectPermissionsTitle}>
            {strings.selectPermissions}
          </Typography>
          <Divider sx={styles.dividerLine} />
        </Grid>
        {(
        Object.keys(permissionLanguageMap) as unknown as Array<
          keyof typeof permissionLanguageMap
        >
        ).map((key) => {
          const label = permissionLanguageMap?.[key];
          const id = permissionTypeMap?.[key];

          return (
            <Grid item xs={6} key={id}>
              <CustomCheckbox
                handleChange={handleChange(id)}
                label={label}
                name="permissions"
                value={formik.values.permissions.includes(id)}
                id="permissions"
                disabled={isSuperAdminSelected}
              />
            </Grid>
          );
        })}

      </Grid>
      <Box>
        {isFormikError && (
        <Typography sx={[genericClasses.error, styles.errorContainer]}>
          {formik.errors[name] as string}
        </Typography>
        )}
      </Box>
    </>
  );
};

export default AdminPermissions;
