const classes = {
  adsContainer: {
    padding: '5px',
    textAlign: 'center',
  },
  adsContainerImg: {
    maxWidth: '100%',
    height: 'auto',
  },
};

export default classes;
